/* 
  Trap focus in the lightbox by sending and receiving postMessages, here and from the modal script, while tabbing through the program.
*/
const trapFocus = () => {
  document.addEventListener("keydown", (e) => {
    const focusableElements =
      'button, .sid-link, input, select, textarea, [tabindex]:not([tabindex="-1"]';
    const focusableContent: NodeListOf<HTMLElement> = document.querySelectorAll(focusableElements);
    const lastFocusableElement: HTMLElement = focusableContent[focusableContent.length - 1];
    const isTabPressed: boolean = e.key === "Tab";

    if (!isTabPressed) {
      return;
    }

    if (lastFocusableElement) {
      window.addEventListener("message", (event) => {
        const message = typeof event.data === "string" ? JSON.parse(event.data) : event.data;

        if (message.focusOn === "lastElement") {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      });

      if (!e.shiftKey && document.activeElement === lastFocusableElement) {
        window.parent.postMessage({ focusOn: "firstElement" }, "*");
        e.preventDefault();
      }
    }
  });

  // set inital focus to lightbox close button
  window.parent.postMessage({ focusOn: "firstElement" }, "*");
};

export const maintainFocusInLightbox = () => {
  window.addEventListener("message", (event) => {
    const message = typeof event.data === "string" ? JSON.parse(event.data) : event.data;

    if (message.isInLightBox === "true") {
      trapFocus();
    }
  });
};
