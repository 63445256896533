import { ProgramTheme, Country } from "../../lib/types/types";
import {
  isValidUsPostalCode,
  getPhoneNumberValidationError,
} from "../../lib/validators/validators";

export const phoneNumberValidator =
  (countryChoiceValue: Country) => (value: string, programTheme?: ProgramTheme) => {
    const errorIfFalsy =
      (programTheme && programTheme.smsLoopEnabled) || countryChoiceValue !== "US";
    return getPhoneNumberValidationError(errorIfFalsy)(value);
  };

export const postalCodeValidator = (countryChoiceValue: Country) => (value: string) => {
  if (countryChoiceValue !== "US") {
    return;
  }
  if (!isValidUsPostalCode(value)) {
    return "invalidPostalCode";
  }
};

export const carrierConsentValueValidator =
  (countryChoiceValue: Country) => (value: boolean, programTheme?: ProgramTheme) => {
    const isRequired = (programTheme && programTheme.smsLoopEnabled) || countryChoiceValue !== "US";
    if (isRequired && !value) {
      return "invalidCarrierConsentValue";
    }
  };
