import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FieldLabel } from "../../FieldLabel";
import { PostalCodeInputComponent } from "../../FormInputs/PostalCodeInput/PostalCodeInputComponent";

interface PassedProps {
  explanation?: string | JSX.Element;
}

const PostalCodeComponent = ({
  value,
  isErrored,
  onChange,
  explanation,
}: FormFieldComponentProps & PassedProps) => (
  <div className="sid-field sid-postal-code">
    <div className="sid-l-space-top-md" />
    <FieldLabel
      text={<FormattedHTMLMessage id="postalCode" defaultMessage="Postal Code" />}
      htmlForLabel="sid-postal-code"
      id="sid-postal-code-label"
      displayClasses="sid-field__label-with-explanation"
    >
      {explanation && explanation}
    </FieldLabel>

    <PostalCodeInputComponent
      value={value}
      isValid={!isErrored}
      onChange={onChange}
      ariaLabelledBy="sid-postal-code-label"
    />
    {isErrored ? (
      <div className="sid-field-error">
        <FormattedHTMLMessage id="errorId.invalidPostalCode" defaultMessage="Invalid postal code" />
      </div>
    ) : null}
  </div>
);

export { PostalCodeComponent };
