/* eslint-disable no-nested-ternary */
// TODO remove eslint-disable no-nested-ternary and fix this file
import React from "react";
import { injectIntl } from "react-intl";
import { produce } from "immer";

import { logger } from "../../lib/utils/logger/logger";
import { VerificationService, StudentPersonalInfoViewModel } from "../../lib/types/types";
import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { getMaxReviewTime, getLogoUrl } from "../../lib/ProgramTheme/programThemeGetters";

import { LogoComponent } from "../LogoComponent/LogoComponent";
import { PoweredByComponent } from "../PoweredBy/PoweredByComponent";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";

import { CountDownComponent } from "./CountDownComponent";
import { SSOPendingComponent } from "./SSOPendingComponent";
import { ReviewPendingComponent } from "./ReviewPendingComponent";
import { usePollingInterval } from "../../lib/utils/usePollingInterval/usePollingInterval";
import { getPreviousStepOverride } from "../../lib/ServerApi/TestingRouteOverrides";

const DOC_UPLOAD_POLL_DURATION_DURING_COUNTDOWN = 5000;
const DOC_UPLOAD_POLL_DURATION = 20000;
const SSO_POLL_DURATION = 5000;

interface StepPendingProps {
  verificationService: VerificationService;
  intl: any;
}

const calculateShowCountdown = (
  isPreviousStepSSO: boolean,
  isMockingSSO: boolean,
  isMockingDocUpload: boolean,
) => !isPreviousStepSSO && !isMockingSSO && !isMockingDocUpload;

const StepPending = ({ verificationService, intl }: StepPendingProps) => {
  const { programId } = verificationService;
  const { viewModel } = verificationService;
  const previousViewModel =
    (verificationService.previousViewModel as StudentPersonalInfoViewModel) || undefined;
  const verificationLastResponse =
    verificationService.verificationResponse.lastResponse ||
    verificationService.previousVerificationResponse ||
    undefined;
  const { verificationId } = verificationService.verificationResponse;
  const hasLogo = !!getLogoUrl(verificationService.programTheme);
  const countdownSeconds = 20;
  const isMockingPreviousStepSSO =
    getPreviousStepOverride() && getPreviousStepOverride() === VerificationStepsEnum.sso;
  const isMockingPreviousStepDocUpload =
    getPreviousStepOverride() && getPreviousStepOverride() === VerificationStepsEnum.docUpload;

  const isPreviousStepSSO = (): boolean => {
    if (
      verificationLastResponse &&
      verificationLastResponse.currentStep === VerificationStepsEnum.sso
    ) {
      return true;
    }
    return false;
  };

  const [showCountDown, setShowCountDown] = React.useState(
    calculateShowCountdown(
      isPreviousStepSSO(),
      isMockingPreviousStepSSO,
      isMockingPreviousStepDocUpload,
    ),
  );

  const getLogo = () =>
    hasLogo ? (
      <LogoComponent verificationService={verificationService} />
    ) : (
      <img
        className="sid-l-lead-image"
        alt="design element"
        src="https://s3.amazonaws.com/com.sheerid.resources/common/images/2018/icons/doc-pending.svg"
      />
    );

  const pollTime = isPreviousStepSSO()
    ? SSO_POLL_DURATION
    : showCountDown
    ? DOC_UPLOAD_POLL_DURATION_DURING_COUNTDOWN
    : DOC_UPLOAD_POLL_DURATION;

  const stopPollingInterval = usePollingInterval(() => {
    verificationService.fetchExistingVerificationRequest(
      programId,
      verificationId,
      verificationLastResponse,
      previousViewModel,
      false,
    );
  }, pollTime);

  const cancelSSOStep = () => {
    const nextState = produce(viewModel, () => {});
    logger.info("StepSSOComponent submitting cancel request");
    stopPollingInterval();

    verificationService.submitStep(VerificationStepsEnum.sso, nextState, verificationLastResponse);
  };

  const onCountdownFinish = () => {
    setShowCountDown(false);
  };

  const logo = getLogo();

  const getPendingContent = () => {
    const showSSO = isPreviousStepSSO() || isMockingPreviousStepSSO;

    if (showCountDown) {
      return (
        <CountDownComponent
          logo={logo}
          onCountdownFinish={onCountdownFinish}
          countdownSeconds={countdownSeconds}
        />
      );
    }

    if (showSSO) {
      return <SSOPendingComponent logo={logo} cancelSSO={cancelSSOStep} />;
    }

    return (
      <ReviewPendingComponent
        verificationService={verificationService}
        logo={logo}
        intl={intl}
        maxReviewTime={getMaxReviewTime(verificationService.programTheme)}
      />
    );
  };

  return (
    <div id="sid-step-pending" className="sid-l-container">
      {getPendingContent()}

      {showCountDown ? (
        <PoweredByComponent verificationService={verificationService} isCentered />
      ) : (
        <FormFooter verificationService={verificationService}>
          <div /> {/* suppress default */}
        </FormFooter>
      )}
    </div>
  );
};

export const StepPendingComponent = injectIntl(StepPending);
