import produce from "immer";
import { militaryReservistOrgNames } from "../../lib/types/runtimeTypes";
import {
  VerificationService,
  FormSelectChoice,
  ActiveMilitaryPersonalInfoViewModel,
  InactiveMilitaryPersonalInfoViewModel,
  MilitaryStatus,
  Organization,
} from "../../lib/types/types";

export const orgsInStatus = (status: MilitaryStatus) => (org: Organization) => {
  if (status === "VETERAN") {
    return !!org.name;
  }
  if (status === "RESERVIST") {
    return militaryReservistOrgNames.includes(org.name);
  }
  return !militaryReservistOrgNames.includes(org.name);
};
export const orgToOption = (org: Organization) => ({ value: org.id, label: org.name });

export const updateMilitaryViewModel = (
  key: keyof ActiveMilitaryPersonalInfoViewModel | keyof InactiveMilitaryPersonalInfoViewModel,
  value: any,
  verificationService: VerificationService,
  availableStatuses: FormSelectChoice[],
): ActiveMilitaryPersonalInfoViewModel | InactiveMilitaryPersonalInfoViewModel => {
  const viewModel = verificationService.viewModel as ActiveMilitaryPersonalInfoViewModel;
  const nextState: ActiveMilitaryPersonalInfoViewModel = produce(
    viewModel,
    (draft: ActiveMilitaryPersonalInfoViewModel) => {
      (draft[key] as any) = value;

      // If there are no status choices, don't validate
      if (!availableStatuses) {
        draft.fieldsToSkipValidation = ["status"];
      }

      if (key === "status") {
        draft.organization = {
          id: 0,
          name: "",
        };
      }
    },
  );
  return nextState;
};
