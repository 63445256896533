import React from "react";
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "react-intl";
import { produce } from "immer";
import Dropzone from "react-dropzone";

import { MAX_DOC_UPLOAD_DOCS_ALLOWED } from "../../constants";
import { SubSegmentEnum, VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { logger } from "../../lib/utils/logger/logger";
import {
  addFiles,
  removeFile,
  removeAllFiles,
} from "../../lib/ServerApi/VerificationRequestSetters";
import { validateFieldById } from "../../lib/validators/validators";
import { getRefByFieldId, setRef } from "../../lib/refs/refs";
import { getLogoUrl } from "../../lib/ProgramTheme/programThemeGetters";
import {
  VerificationService,
  DocUploadViewModel,
  ErrorId,
  DocUploadResponse,
} from "../../lib/types/types";

import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from "../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent";
import { LogoComponent } from "../LogoComponent/LogoComponent";
import { AcceptableUploadsComponent as AcceptableUploads } from "../AcceptableUploadsComponent/AcceptableUploadsComponent";
import { UploadInfoComponent as UploadInfo } from "../UploadInfoComponent/UploadInfoComponent";

import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { getEstAndMaxReviewTimes } from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { getSafe } from "../../lib/utils/objects";
import { RewardsRemainingComponent } from "../RewardsRemaining/RewardsRemainingComponent";

interface StepDocUploadProps {
  verificationService: VerificationService;
  intl: any;
}

interface ImagePreview {
  name: string;
  url: string;
}

const StepDocUpload = ({ verificationService, intl }: StepDocUploadProps) => {
  const verificationResponse: DocUploadResponse =
    verificationService.verificationResponse as DocUploadResponse;
  const { rejectionReasons } = verificationResponse;
  const { programTheme } = verificationService;
  const viewModel: DocUploadViewModel = verificationService.viewModel as DocUploadViewModel;
  const { fieldValidationErrors } = verificationService;
  const hasLogo = !!getLogoUrl(programTheme);
  const subSegment = getSafe(() => verificationService.verificationResponse.subSegment);

  const getFileForView = (file: File) => ({
    name: file.name,
    url: file.type.includes("image") ? URL.createObjectURL(file) : "",
  });

  const currentFileNames: ImagePreview[] = [];
  if (viewModel.file1 && viewModel.file1.name) {
    currentFileNames.push(getFileForView(viewModel.file1));
  }
  if (viewModel.file2 && viewModel.file2.name) {
    currentFileNames.push(getFileForView(viewModel.file2));
  }
  if (viewModel.file3 && viewModel.file3.name) {
    currentFileNames.push(getFileForView(viewModel.file3));
  }

  const addFilesAndUpdate = (files: File[], erroredFilenames?: string[]) => {
    verificationService.updateViewModel(addFiles(viewModel, files, erroredFilenames));
  };

  const removeFileAndUpdate = (arrayIndex: number) => {
    const fileNumber = arrayIndex + 1;
    verificationService.updateViewModel(removeFile(viewModel, fileNumber));
  };

  const removeFilesAndUpdate = () => {
    const nextFieldValidationErrors = produce(fieldValidationErrors, (draft) => {
      draft.docUpload = undefined;
    });

    verificationService.updateFieldValidationErrors(nextFieldValidationErrors);
    verificationService.updateViewModel(removeAllFiles(viewModel));
  };

  const updateFieldValidationErrorsAndAddFiles = (files: File[]) => {
    const acceptedFiles: File[] = [];
    const errors: ErrorId[] = [];
    const erroredFilenames: string[] = [];

    for (const file of files) {
      const fileValidationError: ErrorId = validateFieldById(
        VerificationStepsEnum.docUpload,
        file,
      ) as ErrorId;

      if (fileValidationError === undefined) {
        acceptedFiles.push(file);
      } else {
        erroredFilenames.push(file.name);
        errors.push(fileValidationError);
      }
    }

    let nextFieldValidationErrors;
    if (errors) {
      nextFieldValidationErrors = produce(fieldValidationErrors, (draft) => {
        draft.docUpload = errors[0]; // we only care about one error at a time
      });
    }

    verificationService.updateFieldValidationErrors(nextFieldValidationErrors);
    addFilesAndUpdate(acceptedFiles, erroredFilenames);
  };

  const submitForm = () => {
    logger.info("StepDocUploadComponent submitting form");
    verificationService.submitStep(
      VerificationStepsEnum.docUpload,
      viewModel,
      verificationService.verificationResponse,
    );
  };

  const onFileDrop = (draggedFiles: File[]) => {
    if (draggedFiles.length > 0) {
      updateFieldValidationErrorsAndAddFiles(draggedFiles);
    } else if (draggedFiles.length <= 0) {
      logger.error("No files were accepted or rejected. Unknown error.", "docUpload drag n drop");
    }
  };

  const fileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFileDrop(Array.from(e.target.files));
  };

  const handleAddFileOnKeyDown = (e: React.KeyboardEvent<HTMLLabelElement>) => {
    const addFileRef = getRefByFieldId("addFile");

    if (e.key === "Enter" || e.key === " ") {
      addFileRef.click();
    }
  };

  const errorId =
    fieldValidationErrors.docUpload ||
    getSafe(() => verificationService.verificationResponse.errorIds[0], undefined);
  const conditionalErrorMsg = errorId ? (
    <div className="sid-field-error sid-field-error__doc-upload sid-l-horz-center">
      <FormattedHTMLMessage
        id={`errorId.${fieldValidationErrors.docUpload}`}
        defaultMessage="No valid files"
      />
      {viewModel.erroredFileNames
        ? viewModel.erroredFileNames.map((filename) => <div>{filename}</div>)
        : null}
    </div>
  ) : null;

  return (
    <div id="sid-step-doc-upload" className="sid-l-container">
      <div className="sid-header sid-l-horz-center sid-l-space-top-md">
        <div className="sid-l-horz-center">
          {hasLogo ? (
            <LogoComponent verificationService={verificationService} />
          ) : (
            <img
              className="sid-l-lead-image"
              alt="design element"
              src="https://s3.amazonaws.com/com.sheerid.resources/common/images/2018/icons/doc-upload2.svg"
            />
          )}
        </div>
        <div className="sid-header__title sid-l-horz-center sid-l-space-top-md">
          <FormattedHTMLMessage
            id="step.docUpload.title"
            defaultMessage="We need more information"
          />
        </div>

        <RewardsRemainingComponent verificationService={verificationService} />

        {rejectionReasons && rejectionReasons.length > 0 ? (
          <div className="sid-header__subtitle sid-l-horz-center sid-l-position">
            <div>
              <FormattedMessage
                id="step.docUpload.rejectedSubtitle"
                defaultMessage="We were unable to confirm your eligibility due to:"
              />
            </div>
            <ul className="sid-upload-rejection-list">
              {rejectionReasons.map((rejectionReason) => (
                <li key={rejectionReason} className="sid-upload-rejection-list__li">
                  <FormattedMessage
                    id={`step.docUpload.rejectionReasons.${rejectionReason}`}
                    defaultMessage={`${rejectionReason}`}
                  />
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="sid-header__subtitle sid-l-horz-center sid-l-position">
            <div>
              <FormattedMessage
                id="step.docUpload.subtitle"
                defaultMessage="Documents will be reviewed in {estimatedWaitTime} by staff at SheerID, a trusted partner."
                values={{
                  estimatedWaitTime: getEstAndMaxReviewTimes(
                    verificationResponse,
                    programTheme,
                    intl,
                  ).estReviewTime,
                }}
              />
              &nbsp;
              <HowDoesVerifyingWork verificationService={verificationService} />
            </div>
          </div>
        )}
      </div>

      {currentFileNames.length === 0 ? (
        <div>
          <div className="sid-upload-info sid-l-space-top-md">
            <div className="sid-l-space-top-sm">
              <UploadInfo verificationService={verificationService} />
            </div>
            {subSegment === SubSegmentEnum.MILITARY_FAMILY ? null : (
              <div className="sid-l-space-top-md">
                <AcceptableUploads verificationService={verificationService} />
              </div>
            )}
          </div>
          <div className="sid-upload-wrap sid-l-space-top-sm">
            <div className="sid-dropzone-wrap">
              {conditionalErrorMsg}

              <Dropzone onDrop={onFileDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section {...getRootProps()} className="sid-dropzone-wrap__dropzone">
                    <input {...getInputProps()} />
                    <p className="sid-dropzone-wrap__instructions sid-l-horz-center">
                      <FormattedHTMLMessage
                        id="step.docUpload.fileInstructions"
                        defaultMessage='Drag &amp; drop your files here or <span class="sid-dropzone-wrap__link">browse</span>'
                      />
                    </p>
                  </section>
                )}
              </Dropzone>

              <div className="sid-dropzone-wrap__accepted-types sid-l-space-top-sm">
                <FormattedHTMLMessage
                  id="step.docUpload.acceptedTypes"
                  defaultMessage="Acceptable file types: png, gif, jpg, bmp, pdf"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="sid-upload-wrap sid-l-space-top-sm">
        {currentFileNames.length > 0 ? (
          <div className="sid-file-list sid-l-space-top-md">
            {currentFileNames.map((file, arrayIndex) => (
              <div className="sid-file-list__file" key={file.name}>
                {file.url ? (
                  <img className="sid-file-list__thumbnail" src={file.url} alt="File thumbnail" />
                ) : (
                  <div className="sid-file-list__no-thumbnail">
                    <img
                      alt="File thumbnail"
                      src="https://s3.amazonaws.com/com.sheerid.resources/common/images/2018/icons/default-image.svg"
                    />
                  </div>
                )}
                <div className="sid-file-list__name">{file.name}</div>
                <div
                  role="button"
                  tabIndex={0}
                  aria-label={`Remove file ${file.name}`}
                  className="sid-file-list__remove-btn"
                  onClick={() => removeFileAndUpdate(arrayIndex)}
                  onKeyPress={() => removeFileAndUpdate(arrayIndex)}
                />
              </div>
            ))}

            {conditionalErrorMsg}

            {currentFileNames.length < MAX_DOC_UPLOAD_DOCS_ALLOWED ? (
              <div className="sid-add-more-files">
                <label
                  className="sid-add-more-files__label sid-add-more-files--selectable"
                  htmlFor="file"
                  aria-label="add file"
                  id="sid-add-file"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => handleAddFileOnKeyDown(e)}
                >
                  <input
                    type="file"
                    id="file"
                    tabIndex={-1}
                    className="sid-add-more-files__input"
                    aria-describedby="sid-add-file"
                    onChange={fileHandler}
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/bmp,image/gif"
                    ref={(input: HTMLInputElement) => setRef("addFile", input)}
                  />
                  <img
                    className="sid-add-more-files__image"
                    alt="add more files"
                    src="https://s3.amazonaws.com/com.sheerid.resources/common/images/2018/icons/add-file.svg"
                  />
                  <FormattedMessage id="step.docUpload.addFile" defaultMessage="Add file" />
                </label>
              </div>
            ) : null}
            <div className="sid-submit sid-l-space-top-md">
              <button
                id="sid-submit-doc-upload"
                onClick={submitForm}
                type="submit"
                className="sid-btn sid-btn--dark sid-submit__continue"
                aria-label={intl.formatMessage({
                  id: "step.docUpload.submitButtonLabel",
                  defaultMessage: "Continue",
                })}
                disabled={currentFileNames.length <= 0}
              >
                <FormattedHTMLMessage
                  id="step.docUpload.submitButtonLabel"
                  defaultMessage="Continue"
                />
              </button>
              <button
                onClick={removeFilesAndUpdate}
                type="button"
                className="sid-btn sid-btn--light sid-submit__cancel sid-l-space-left-sm"
                aria-label="cancel"
                disabled={currentFileNames.length <= 0}
              >
                <FormattedHTMLMessage
                  id="step.docUpload.cancelButtonLabel"
                  defaultMessage="Cancel"
                />
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <FormFooter verificationService={verificationService} />
    </div>
  );
};

export const StepDocUploadComponent = injectIntl(StepDocUpload);
