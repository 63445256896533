import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FieldLabel } from "../../FieldLabel";
import { InputTextComponent } from "../../FormInputs/InputText/InputText";

interface PassedProps {
  onKeyDown?: Function;
}

export const Address = ({
  value,
  isErrored,
  onChange,
  intl,
  onKeyDown = undefined,
  placeholder = "",
}: FormFieldComponentProps & PassedProps) => (
  <div className="sid-field sid-address">
    <div className="sid-l-space-top-md" />
    <FieldLabel
      text={<FormattedHTMLMessage id="address" defaultMessage="Address" />}
      htmlForLabel="sid-address"
      id="sid-address-label"
      displayClasses={placeholder && "sid-h-screen-reader-only"}
    />

    <InputTextComponent
      id="address"
      isErrored={isErrored}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={typeof onKeyDown === "function" ? (e) => onKeyDown(e) : undefined}
      placeholder={
        placeholder || intl.formatMessage({ id: "addressPlaceholder", defaultMessage: "Address*" })
      }
      value={value}
      aria-labelledby="sid-address-label"
    />

    {isErrored ? (
      <div className="sid-field-error">
        <FormattedHTMLMessage id="errorId.invalidAddress" defaultMessage="Invalid address" />
      </div>
    ) : null}
  </div>
);

export const AddressComponent = injectIntl(Address);
