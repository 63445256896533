import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FieldLabel } from "../../FieldLabel";
import { InputTextComponent } from "../../FormInputs/InputText/InputText";

interface PassedProps {
  explanation?: string | JSX.Element;
  onKeyDown?: Function;
}

export const Email = ({
  value,
  isErrored,
  onChange,
  explanation,
  intl,
  onKeyDown = undefined,
  placeholder = "",
}: FormFieldComponentProps & PassedProps) => (
  <div className="sid-field sid-email">
    <div className="sid-l-space-top-md" />
    <FieldLabel
      text={<FormattedHTMLMessage id="emailAddress" defaultMessage="Email address" />}
      htmlForLabel="sid-email"
      id="sid-email-label"
      displayClasses={`sid-field__label-with-explanation${
        placeholder && " sid-h-screen-reader-only"
      }`}
    >
      {explanation || (
        <FormattedHTMLMessage
          id="emailExplanation"
          defaultMessage="Needed to send you your unique code"
        />
      )}
    </FieldLabel>

    <InputTextComponent
      id="email"
      isErrored={isErrored}
      onChange={(event) => onChange(event.target.value)}
      onKeyDown={typeof onKeyDown === "function" ? (e) => onKeyDown(e) : undefined}
      placeholder={
        placeholder ||
        intl.formatMessage({ id: "emailAddressPlaceholder", defaultMessage: "Email*" })
      }
      type="email"
      value={value}
      required
      aria-labelledby="sid-email-label"
    />

    {isErrored ? (
      <div className="sid-field-error">
        <FormattedHTMLMessage id="errorId.invalidEmail" defaultMessage="Invalid email" />
      </div>
    ) : null}
  </div>
);

export const EmailComponent = injectIntl(Email);
