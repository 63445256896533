import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { VerificationService, Segment } from "../lib/types/types";
import { getSafe } from "../lib/utils/objects";

interface TryAgainButtonComponentProps {
  verificationService: VerificationService;
}

const TryAgainButtonComponent = ({ verificationService }: TryAgainButtonComponentProps) => {
  const currentSegment = getSafe<Segment>(() => verificationService.verificationResponse.segment);

  const fetchNewVerificationRequest = React.useCallback(() => {
    const trackingId = undefined;
    const forceNewVerificationRequest = true;

    verificationService.fetchNewVerificationRequest(
      verificationService.programId,
      currentSegment,
      verificationService.previousViewModel,
      trackingId,
      forceNewVerificationRequest,
    );
  }, [verificationService.programId, currentSegment, verificationService.previousViewModel]);

  return (
    <button
      type="button"
      className="sid-btn sid-btn-light"
      aria-label="submit"
      onClick={fetchNewVerificationRequest}
      onKeyPress={fetchNewVerificationRequest}
      tabIndex={0}
    >
      <FormattedHTMLMessage id="tryAgain" defaultMessage="Try again" />
    </button>
  );
};

export { TryAgainButtonComponent };
