import React from "react";
import { FormattedMessage } from "react-intl";
import { VerificationService } from "../../lib/types/types";
import { assertValidSegmentName } from "../../lib/types/assertions";
import { SegmentEnum, SubSegmentEnum } from "../../lib/types/runtimeTypes";

interface Props {
  verificationService: VerificationService;
}

const UploadInfoComponent = ({ verificationService }: Props) => {
  const { segment } = verificationService.verificationResponse;
  const { subSegment } = verificationService.verificationResponse;
  assertValidSegmentName(segment);

  switch (segment) {
    case SegmentEnum.STUDENT:
      return (
        <div>
          <span>
            <strong>
              <FormattedMessage
                id="step.docUpload.uploadInstructions"
                defaultMessage="Upload your school-issued document that shows:"
              />
            </strong>
          </span>
          <ul className="sid-upload-info-list">
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.fullName"
                defaultMessage="Your full name"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.student.school"
                defaultMessage="Your school"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.student.enrollmentDate"
                defaultMessage="Date proving enrollment for the current term (or within last 3 months)"
              />
            </li>
          </ul>
        </div>
      );
    case SegmentEnum.TEACHER:
      return (
        <div>
          <span>
            <strong>
              <FormattedMessage
                id="step.docUpload.uploadInstructions"
                defaultMessage="Upload your school-issued document that shows:"
              />
            </strong>
          </span>
          <ul className="sid-upload-info-list">
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.fullName"
                defaultMessage="Your full name"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.teacher.school"
                defaultMessage="Your school"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.teacher.currentSchoolYear"
                defaultMessage="A date in the current school year"
              />
            </li>
          </ul>
        </div>
      );
    case SegmentEnum.MEMBER:
      return (
        <div>
          <span>
            <strong>
              <FormattedMessage
                id="step.docUpload.uploadInstructions"
                defaultMessage="Upload an official document that shows:"
              />
            </strong>
          </span>
          <ul className="sid-upload-info-list">
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.fullName"
                defaultMessage="Your full name"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.organization"
                defaultMessage="Your organization"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.affiliation"
                defaultMessage="Your current affiliation with that organization"
              />
            </li>
          </ul>
        </div>
      );
    case SegmentEnum.MILITARY:
      switch (subSegment) {
        case SubSegmentEnum.MILITARY_FAMILY:
          return (
            <div>
              <span>
                <strong>
                  <FormattedMessage
                    id="step.docUpload.uploadInfo.military.uploadInstructionsDependent"
                    defaultMessage="Please upload a Dependent ID card that clearly shows your first name, last name, valid dates and status"
                  />
                </strong>
              </span>
            </div>
          );
        default:
          return (
            <div>
              <span>
                <strong>
                  <FormattedMessage
                    id="step.docUpload.uploadInstructions"
                    defaultMessage="Upload your government-issued document that shows:"
                  />
                </strong>
              </span>
              <ul className="sid-upload-info-list">
                <li className="sid-upload-info-list__li">
                  <FormattedMessage
                    id="step.docUpload.uploadInfo.fullName"
                    defaultMessage="Your full name"
                  />
                </li>
                <li className="sid-upload-info-list__li">
                  <FormattedMessage
                    id="step.docUpload.uploadInfo.military.serviceBranch"
                    defaultMessage="Your branch of service"
                  />
                </li>
                <li className="sid-upload-info-list__li">
                  <FormattedMessage
                    id="step.docUpload.uploadInfo.military.dischargeDate"
                    defaultMessage="Your discharge date (if applicable)"
                  />
                </li>
                <li className="sid-upload-info-list__li">
                  <FormattedMessage
                    id="step.docUpload.uploadInfo.military.currentAffiliation"
                    defaultMessage="Your current affiliation with the armed services"
                  />
                </li>
              </ul>
            </div>
          );
      }
    case SegmentEnum.SENIOR:
      return (
        <div>
          <span>
            <strong>
              <FormattedMessage
                id="step.docUpload.uploadInstructions"
                defaultMessage="Upload a government-issued document that shows:"
              />
            </strong>
          </span>
          <ul className="sid-upload-info-list">
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.fullName"
                defaultMessage="Your full name"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.senior.birthDate"
                defaultMessage="Your date of birth"
              />
            </li>
          </ul>
        </div>
      );
    case SegmentEnum.AGE:
      return (
        <div>
          <span>
            <strong>
              <FormattedMessage
                id="step.docUpload.uploadInstructions"
                defaultMessage="Upload a government-issued document that shows:"
              />
            </strong>
          </span>
          <ul className="sid-upload-info-list">
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.fullName"
                defaultMessage="Your full name"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.age.birthDate"
                defaultMessage="Your date of birth"
              />
            </li>
          </ul>
        </div>
      );
    case SegmentEnum.FIRST_RESPONDER:
      return (
        <div>
          <span>
            <strong>
              <FormattedMessage
                id="step.docUpload.uploadInstructions"
                defaultMessage="Upload an official document that shows:"
              />
            </strong>
          </span>
          <ul className="sid-upload-info-list">
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.fullName"
                defaultMessage="Your full name"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.firstResponder.organization"
                defaultMessage="Your organization"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.firstResponder.currentAffiliation"
                defaultMessage="Your current affiliation with that organization"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.firstResponder.license"
                defaultMessage="Your status or license as a First Responder"
              />
            </li>
          </ul>
        </div>
      );
    case SegmentEnum.MEDICAL:
      return (
        <div>
          <span>
            <strong>
              <FormattedMessage
                id="step.docUpload.uploadInstructions"
                defaultMessage="Upload an official document that shows:"
              />
            </strong>
          </span>
          <ul className="sid-upload-info-list">
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.fullName"
                defaultMessage="Your full name"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.medical.status"
                defaultMessage="Your nursing status"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.medical.validDate"
                defaultMessage="A currently valid date"
              />
            </li>
          </ul>
        </div>
      );
    case SegmentEnum.EMPLOYMENT:
      return (
        <div>
          <span>
            <strong>
              <FormattedMessage
                id="step.docUpload.uploadInstructions"
                defaultMessage="Upload an official document that shows:"
              />
            </strong>
          </span>
          <ul className="sid-upload-info-list">
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.employment.company"
                defaultMessage="Your company"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.employment.currentAffiliation"
                defaultMessage="Your current affiliation with that company"
              />
            </li>
          </ul>
        </div>
      );
    case SegmentEnum.IDENTITY:
      return (
        <div>
          <span>
            <strong>
              <FormattedMessage
                id="step.docUpload.uploadInstructions"
                defaultMessage="Upload an official document that shows:"
              />
            </strong>
          </span>
          <ul className="sid-upload-info-list">
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.fullName"
                defaultMessage="Your full name"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.identity.validDate"
                defaultMessage="A currently valid date"
              />
            </li>
          </ul>
        </div>
      );
    case SegmentEnum.LICENSED_PROFESSIONAL:
      return (
        <div>
          <span>
            <strong>
              <FormattedMessage
                id="step.docUpload.uploadInstructions"
                defaultMessage="Upload an official document that shows:"
              />
            </strong>
          </span>
          <ul className="sid-upload-info-list">
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.fullName"
                defaultMessage="Your full name"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.licensedProfessional.licenseStatus"
                defaultMessage="Your professional license and/or certification status"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.identity.validDate"
                defaultMessage="A currently valid date"
              />
            </li>
          </ul>
        </div>
      );
    case SegmentEnum.RECENT_MOVER:
      return (
        <div>
          <span>
            <strong>
              <FormattedMessage
                id="step.docUpload.uploadInstructions"
                defaultMessage="Upload an official document that shows:"
              />
            </strong>
          </span>
          <ul className="sid-upload-info-list">
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.fullName"
                defaultMessage="Your full name"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.recentMover.address"
                defaultMessage="Your new address"
              />
            </li>
            <li className="sid-upload-info-list__li">
              <FormattedMessage
                id="step.docUpload.uploadInfo.recentMover.validDate"
                defaultMessage="A valid date for your recent move"
              />
            </li>
          </ul>
        </div>
      );
    default:
      return null;
  }
};

export { UploadInfoComponent };
