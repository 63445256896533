import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormSelectChoice, InputSelectComponentProps } from "../../../lib/types/types";
import { FieldLabel } from "../../FieldLabel";
import { InputSelectComponent } from "../../FormInputs/InputSelect/InputSelectComponent";

export const Country = ({
  value,
  isErrored,
  options,
  onChange,
  onKeyDown,
  intl,
  placeholder = "",
  isRequired = false,
}: InputSelectComponentProps) => {
  const inputId = "sid-country";

  return (
    <div className={`sid-field ${inputId}`}>
      <div className="sid-l-space-top-md" />
      <FieldLabel
        text={<FormattedHTMLMessage id="country" defaultMessage="Country" />}
        htmlForLabel={inputId}
        id={`${inputId}-label`}
        displayClasses={placeholder && "sid-h-screen-reader-only"}
        isRequired={isRequired}
      />

      <InputSelectComponent
        fieldId="country"
        inputId={inputId}
        fieldLabelId={`${inputId}-label`}
        isErrored={isErrored}
        options={options}
        onChange={(country: FormSelectChoice) => onChange(country)}
        onKeyDown={onKeyDown}
        placeholder={
          placeholder ||
          intl.formatMessage({ id: "countryPlaceholder", defaultMessage: "Country*" })
        }
        value={
          value
            ? {
                value: value.value,
                label: intl.formatMessage({
                  id: `countries.${value.value}`,
                  defaultMessage: value.label || value.value,
                }),
              }
            : undefined
        }
        isRequired
      />
      {isErrored ? (
        <div className="sid-field-error">
          <FormattedHTMLMessage id="errorId.invalidCountry" defaultMessage="Invalid country" />
        </div>
      ) : null}
    </div>
  );
};

export const CountryComponent = injectIntl(Country);
