import React from "react";

interface Props {
  styles?: string;
  children?: JSX.Element | any;
}

const removePollutingStyles = (styles: string): string => {
  if (window.self === window.top) {
    return styles
      .replace(/body,/g, "")
      .replace(/label,/g, "")
      .replace(/button\[type=('|")submit('|")\]\s*?\{[\s\S]*?\}\n?/g, "") // button type submit overwrites
      .replace(/a:link,\s+a:visited,\s+a:hover,\s+a:active\s*?\{[\s\S]*?\}\n?/g, "") // links overwrites
      .replace(/h1\s*?\{[\s\S]*?\}\n?/g, ""); // h1 overwrites
  }
  return styles;
};

const CustomCssWrapperComponent = ({ styles, children }: Props) => (
  <div>
    {styles ? <style>{removePollutingStyles(styles)}</style> : null}

    {children}
  </div>
);

CustomCssWrapperComponent.defaultProps = {
  styles: "",
  children: "",
};

export { CustomCssWrapperComponent };
