import { DefaultMessages } from "../../types/types";

export const localesMessages: DefaultMessages["locales"] = {
  "en-US": "English",
  ar: "Arabic",
  bg: "Bulgarian",
  "zh-HK": "Chinese (Hong Kong)",
  zh: "Chinese (Traditional)",
  hr: "Croatian",
  cs: "Czech",
  da: "Danish",
  nl: "Dutch",
  "en-GB": "English (UK)",
  fi: "Finnish",
  "fr-CA": "French (Canadian)",
  fr: "French",
  ga: "Irish",
  de: "German",
  el: "Greek",
  iw: "Hebrew",
  hu: "Hungarian",
  id: "Indonesian",
  it: "Italian",
  ja: "Japanese",
  ko: "Korean",
  lo: "Lao",
  lt: "Lithuanian",
  ms: "Malay",
  no: "Norwegian",
  pl: "Polish",
  "pt-BR": "Portuguese (Brazil)",
  pt: "Portuguese",
  ru: "Russia",
  sr: "Serbian",
  sk: "Slovak",
  sl: "Slovenian",
  es: "Spanish",
  "es-ES": "Spanish (Spain)",
  sv: "Swedish",
  th: "Thai",
  tr: "Turkish",
};
