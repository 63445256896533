import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FieldLabel } from "../../FieldLabel";
import { InputTextComponent } from "../../FormInputs/InputText/InputText";

interface PassedProps {
  explanation?: string | JSX.Element; // eslint-disable-line
  onKeyDown?: Function;
}

export const State = ({
  value,
  isErrored,
  onChange,
  intl,
  onKeyDown = undefined,
  placeholder = "",
}: FormFieldComponentProps & PassedProps) => (
  <div className="sid-field sid-state">
    <div className="sid-l-space-top-md" />
    <FieldLabel
      text={<FormattedHTMLMessage id="state" defaultMessage="State" />}
      htmlForLabel="sid-state"
      id="sid-state-label"
      displayClasses={placeholder && "sid-h-screen-reader-only"}
    />

    <InputTextComponent
      id="state"
      isErrored={isErrored}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={typeof onKeyDown === "function" ? (e) => onKeyDown(e) : undefined}
      placeholder={
        placeholder || intl.formatMessage({ id: "statePlaceholder", defaultMessage: "State*" })
      }
      value={value}
      aria-labelledby="sid-state-label"
    />

    {isErrored ? (
      <div className="sid-field-error">
        <FormattedHTMLMessage id="errorId.invalidState" defaultMessage="Invalid state" />
      </div>
    ) : null}
  </div>
);

export const StateComponent = injectIntl(State);
