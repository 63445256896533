import React from "react";

import produce from "immer";
import { InjectedIntl, injectIntl } from "react-intl";
import { CountryComponent } from "./CountryComponent";
import {
  VerificationService,
  Country,
  FormSelectChoice,
  ViewModel,
  ErrorId,
  FieldId,
} from "../../../lib/types/types";

import {
  getAvailableCountryChoices,
  getDefaultCountryChoice,
  updateFieldValidationErrorsByFieldId,
  produceDraftViewModel,
  updateFieldValidationErrors,
} from "../../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import { validateFieldById } from "../../../lib/validators/validators";

type CountryComponentWrapperProps = {
  verificationService: VerificationService;
  viewModel: ViewModel;
  intl: InjectedIntl;
  nextFocusField?: string; // deprecated
  viewModelDraftDecorator?: (
    draft: ViewModel,
    countryChoice?: FormSelectChoice<Country, string>,
  ) => void;
};

const BaseCountryComponentWrapper: React.FC<CountryComponentWrapperProps> = ({
  verificationService,
  viewModel,
  intl,
  viewModelDraftDecorator,
}: {
  verificationService: VerificationService;
  viewModel: ViewModel;
  // TODO better types
  intl: any;
  viewModelDraftDecorator: any;
}) => {
  const countryChoices: FormSelectChoice<Country, string>[] = getAvailableCountryChoices(
    verificationService.programTheme,
    intl,
  );
  const defaultCountryChoice: FormSelectChoice<Country, string> =
    getDefaultCountryChoice(countryChoices);
  const { fieldValidationErrors } = verificationService;
  const hasMultipleCountries = countryChoices.length > 1;
  const [firstRender, setFirstRender] = React.useState(true);

  const updateCountryChoice = (countryChoice?: FormSelectChoice<Country, string>) => {
    const nextState: ViewModel = produce(viewModel, (draft: ViewModel) => {
      draft.countryChoice = countryChoice || undefined;

      // Adds backwards compatibility with Age and Employment programs
      (draft as any).country = countryChoice ? countryChoice.value : undefined;

      if (viewModelDraftDecorator) {
        viewModelDraftDecorator(draft, countryChoice);
      }
    });
    verificationService.updateViewModel(nextState);
  };

  React.useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      const countryChoice = viewModel.countryChoice
        ? viewModel.countryChoice
        : defaultCountryChoice;
      updateCountryChoice(countryChoice);

      return;
    }
    if (!hasMultipleCountries) {
      const fieldsToSkip: FieldId[] = viewModel.fieldsToSkipValidation
        ? [...viewModel.fieldsToSkipValidation, "country"]
        : ["country"];
      verificationService.updateViewModel(
        produceDraftViewModel(viewModel, "fieldsToSkipValidation", fieldsToSkip),
      );
    } else {
      const updatedFieldValidationErrors = produce(
        verificationService.fieldValidationErrors,
        (draft) => {
          if (draft.country) {
            draft.country = validateFieldById(
              "country",
              viewModel.countryChoice,
              verificationService.formValidationOptions,
            ) as ErrorId;
          }
          if ("postalCode" in viewModel && viewModel.postalCode) {
            draft.postalCode = validateFieldById(
              "postalCode",
              viewModel.postalCode,
              verificationService.formValidationOptions,
            ) as ErrorId;
          }
        },
      );
      updateFieldValidationErrors(updatedFieldValidationErrors, verificationService);
    }
  }, [viewModel.countryChoice, hasMultipleCountries, firstRender]);

  if (!hasMultipleCountries) {
    return null;
  }

  return (
    <CountryComponent
      isRequired
      options={countryChoices}
      value={viewModel.countryChoice}
      isErrored={Boolean(fieldValidationErrors.country)}
      onChange={async (countryChoice: FormSelectChoice<Country, string>) => {
        const countryValue = countryChoice ? countryChoice.value : undefined;
        updateCountryChoice(countryChoice);
        updateFieldValidationErrorsByFieldId("country", countryValue, verificationService);
      }}
    />
  );
};

export const CountryComponentWrapper = injectIntl(BaseCountryComponentWrapper);
