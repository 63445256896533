import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { requestOrganizationConstants } from "../../constants";
import { RequestOrganizationService } from "../../lib/types/types";
import { getLogoUrl } from "../../lib/ProgramTheme/programThemeGetters";

interface RequestOrganizationErrorComponentProps {
  requestOrganizationService: RequestOrganizationService;
}

export const RequestOrganizationErrorComponent = ({
  requestOrganizationService,
}: RequestOrganizationErrorComponentProps) => {
  const { programTheme } = requestOrganizationService;
  const { programId } = requestOrganizationService;

  const logoUrl = programTheme ? getLogoUrl(programTheme) : null;

  return (
    <div className="sid-l-container--extra-wide sid-l-space-btm-lg sid-l-container">
      <div className="sid-logo sid-l-horz-center sid-l-space-top-lg">
        <img
          className="sid-logo__img"
          alt="Logo"
          src={logoUrl || requestOrganizationConstants.SHEERID_LOGO_URL}
        />
      </div>
      <div className="sid-l-horz-center">
        <div className="sid-logo sid-l-space-top-lg">
          <img
            className="sid-logo__icon"
            alt="Error"
            src="https://s3.amazonaws.com/com.sheerid.resources/common/images/requestOrganization/circle-exclamation.svg"
          />
        </div>
        <div className="sid-title">
          <FormattedHTMLMessage
            id="step.error.title"
            defaultMessage="An unexpected error occurred"
          />
        </div>
        <div className="sid-soft-font-color">
          <FormattedHTMLMessage
            id="step.error.description"
            defaultMessage="Sorry we are not able to process your request. Please try again later."
          />
        </div>
        <div className="sid-soft-font-color">
          <FormattedHTMLMessage
            id="step.error.seeingProblem"
            defaultMessage="DM If you keep seeing this problem <a class='sid-link' href='{feedbackUrl}&pid={programId}' target='_blank' rel='noopener noreferrer'>contact us</a>."
            values={{
              programId,
              feedbackUrl: requestOrganizationConstants.FEEDBACK_FORM_URL,
            }}
          />
        </div>
      </div>
    </div>
  );
};
