import { iframeConstants as constants } from "../../constants";
import Iframe from "./iframe";
import { fadeInElements, removeElement, getVerificationUrl } from "./utils";

class Modal {
  verificationUrl: string;

  redirectOnMobile: boolean;

  overlay: HTMLElement;

  wrapper: HTMLElement;

  iframe: HTMLIFrameElement;

  isMobileDevice: boolean;

  closeButtonText: string;

  closeButton: HTMLButtonElement;

  stopPropagation: boolean;

  constructor(url: string, userConfig: any) {
    const thresholdConfig = userConfig.mobileThreshold
      ? userConfig.mobileThreshold
      : constants.DEFAULT_MOBILE_THRESHOLD_WIDTH;
    this.verificationUrl = getVerificationUrl(url);
    this.redirectOnMobile = userConfig.mobileRedirect || false;
    this.isMobileDevice = window.innerWidth <= thresholdConfig;
    this.closeButtonText = userConfig.closeButtonText ? userConfig.closeButtonText : "";
    this.closeButton = null;
    this.stopPropagation = userConfig.stopPropagation || false;
    this.overlay = Modal.createOverlay();
    this.wrapper = this.createWrapper();
    this.addFocusListener();
  }

  createCloseBtn() {
    this.closeButton = document.createElement("button");
    this.closeButton.classList.add(constants.CLASS_NAMES.CLOSE_BUTTON);

    if (this.closeButtonText) {
      const closeTxt = document.createElement("div");
      closeTxt.classList.add(constants.CLASS_NAMES.CLOSE_TEXT);
      closeTxt.innerHTML = this.closeButtonText;
      this.closeButton.appendChild(closeTxt);
    }

    const closeIcon = document.createElement("span");
    closeIcon.classList.add(constants.CLASS_NAMES.CLOSE_ICON);
    closeIcon.setAttribute("role", "img");
    this.closeButton.appendChild(closeIcon);

    this.closeButton.addEventListener("click", (e) => {
      e.preventDefault();
      if (this.stopPropagation) {
        e.stopPropagation();
      }
      this.closeModal();
    });
    return this.closeButton;
  }

  closeModal(historyEventTrigger: boolean = false) {
    window.removeEventListener("popstate", this.popStateEventHandler);
    const transitionTimeout = constants.MODAL_OPACITY_TRANSITION_PERIOD;
    removeElement(this.overlay, transitionTimeout);
    removeElement(this.wrapper, transitionTimeout);
    if (!historyEventTrigger) {
      window.history.back();
    }
  }

  static createOverlay(): HTMLElement {
    const overlay = document.createElement("div");
    overlay.classList.add(constants.CLASS_NAMES.OVERLAY);
    return overlay;
  }

  createWrapper(): HTMLElement {
    const wrapper = document.createElement("div");
    wrapper.classList.add(constants.CLASS_NAMES.MODAL_WRAPPER);
    wrapper.tabIndex = -1;
    wrapper.setAttribute("role", "dialog");
    wrapper.appendChild(this.createCloseBtn());

    const iframe = new Iframe(wrapper, this.verificationUrl);
    const options = {
      className: constants.CLASS_NAMES.MODAL_IFRAME,
    };
    this.iframe = iframe.createIframe(options);
    wrapper.appendChild(this.iframe);
    return wrapper;
  }

  popStateEventHandler = () => this.closeModal(true);

  addPopStateListener() {
    window.addEventListener("popstate", this.popStateEventHandler);
  }

  addFocusListener() {
    window.addEventListener("message", (event) => {
      const message = typeof event.data === "string" ? JSON.parse(event.data) : event.data;
      if (message.focusOn === "firstElement") {
        this.closeButton.focus();
      }
    });

    document.addEventListener("keydown", (e) => {
      const isTabPressed: boolean = e.key === "Tab";
      if (!isTabPressed) {
        return;
      }
      if (e.shiftKey && document.activeElement === this.closeButton) {
        this.iframe.contentWindow.postMessage({ focusOn: "lastElement" }, "*");
        e.preventDefault();
      }
    });
  }

  isInLightboxPostMessage(inLightbox: string) {
    this.iframe.contentWindow.postMessage({ isInLightBox: inLightbox }, "*");
  }

  displayModal() {
    this.iframe.addEventListener("load", () => {
      fadeInElements([this.overlay, this.wrapper]);
      document.body.style.overflow = "hidden";
      this.isInLightboxPostMessage("true");
    });

    document.body.appendChild(this.overlay);
    document.body.appendChild(this.wrapper);
    window.history.pushState({}, document.title);
    this.addPopStateListener();
  }

  init() {
    if (this.redirectOnMobile && this.isMobileDevice) {
      window.top.location.href = this.verificationUrl;
    } else {
      this.displayModal();
    }
  }
}

export default Modal;
