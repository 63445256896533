import React from "react";
import { FormattedHTMLMessage, injectIntl, InjectedIntlProps } from "react-intl";
import { FormSelectChoice, InputSelectComponentProps } from "../../../lib/types/types";
import { InputSelectComponent } from "../../FormInputs/InputSelect/InputSelectComponent";
import { SortByLabel } from "../../../lib/utils/structures/FormSelectChoice";
import { FieldLabel } from "../../FieldLabel";

export const MilitaryStatus = ({
  value,
  isErrored,
  options,
  onChange,
  intl,
  placeholder = "",
}: InputSelectComponentProps & InjectedIntlProps) => {
  const inputId = "sid-military-status";

  return (
    <div className={`sid-field ${inputId}`}>
      <div className="sid-l-space-top-md" />
      <FieldLabel
        text={<FormattedHTMLMessage id="status" defaultMessage="Status" />}
        htmlForLabel={inputId}
        id={`${inputId}-label`}
        displayClasses={placeholder && "sid-h-screen-reader-only"}
      />

      <InputSelectComponent
        fieldId="status"
        inputId={inputId}
        fieldLabelId={`${inputId}-label`}
        isErrored={isErrored}
        options={SortByLabel(options)}
        onChange={(status: FormSelectChoice) => onChange(status)}
        value={value}
        placeholder={
          placeholder || intl.formatMessage({ id: "statusPlaceholder", defaultMessage: "Status*" })
        }
      />

      {isErrored ? (
        <div className="sid-field-error">
          <FormattedHTMLMessage
            id="errorId.invalidStatus"
            defaultMessage="Invalid military status"
          />
        </div>
      ) : null}
    </div>
  );
};

export const MilitaryStatusComponent = injectIntl(MilitaryStatus);
