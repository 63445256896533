/* tslint:disable:max-line-length */
import { SegmentSpecificMessages } from "../../types/types";

export const militaryMessages: SegmentSpecificMessages = {
  emailExplanation: "Personal email address is recommended",
  step: {
    personalInfo: {
      title: "Unlock this Military-Only Offer",
      subtitle: "Verify you're a member of the armed forces.",
      howDoesVerifyingWorkDetails: `
            This offer is available to military personnel who have served or are currently serving in the United States Armed Forces.
            <br><br>
            {companyName} uses SheerID, a trusted partner, to verify that you are a member of the military. SheerID only collects information
            that assists in verifying your eligibility. Additional information or documentation may be requested.`,
      tryAgain: {
        title: "Check Your Name & Birthdate",
        subtitle:
          "Enter your first and last name on the form as it appears on your military document(s). Keep in mind that may be your given name.",
      },
      verifyingTitle: "Verifying your military status",
    },
    docUpload: {
      title: "We need more information",
      subtitle:
        "Documents will be reviewed in {estimatedWaitTime} by staff at SheerID, a trusted partner.",
      howDoesVerifyingWorkDetails: `Document review is handled by SheerID, a trusted partner who specializes in verifying military.
            <br><br>
            Documents are used only for verifying your military status is current and will be permanently deleted after review is finalized.
            They will not be shared with {companyName}`,
      uploadInstructions: "Upload your government-issued document that shows:",
    },
    success: {
      title: "You've been verified",
      subtitle: "Here is your personal coupon code. It is unique to you and can only be used once.",
      redirectButtonText: "Use code now",
    },
  },
};
