import { iframeConstants as constants, QUERY_STRING_INSTALL_PAGE_URL } from "../../constants";
import { getCurrentUrl } from "../ServerApi/ApiUrls";
import { logger } from "../utils/logger/logger";
import { getVerificationUrl } from "./utils";

type IframeOptions = {
  className: string;
};

class Iframe {
  containerElement: HTMLElement;

  verificationUrl: URL;

  isMobileDevice: boolean;

  iframe: HTMLIFrameElement;

  verificationIframeUid: string;

  constructor(containerElement: HTMLElement, url: string) {
    if (Iframe.isValidHttpUrl(url)) {
      const options = {
        className: constants.CLASS_NAMES.INLINE_IFRAME_CONTENT,
      };
      this.containerElement = containerElement;
      this.verificationUrl = new URL(getVerificationUrl(url));
      this.verificationIframeUid = Iframe.createUniqueId();
      this.createIframe(options);
      this.addVerificationSizeUpdatesListener();
    } else {
      logger.error("Invalid URL. Provide a proper URL: https://example.com/", "iframe url");
    }
  }

  static createUniqueId(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  static isValidHttpUrl(urlString: string): boolean {
    try {
      const url = new URL(urlString);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch {
      return false;
    }
  }

  createIframe(options: IframeOptions): HTMLIFrameElement {
    this.iframe = document.createElement("iframe");
    this.iframe.classList.add(options.className);
    this.iframe.title = this.verificationUrl.href;
    let mark = "?";
    if (this.verificationUrl.search) {
      mark = "&";
    }
    this.iframe.src = `${this.verificationUrl.href}${mark}verificationIframeUid=${
      this.verificationIframeUid
    }&${QUERY_STRING_INSTALL_PAGE_URL}=${encodeURIComponent(getCurrentUrl())}`;
    return this.iframe;
  }

  addVerificationSizeUpdatesListener() {
    window.addEventListener("message", (event) => {
      if (this.verificationUrl.origin !== event.origin) {
        return;
      }

      const message = typeof event.data === "string" ? JSON.parse(event.data) : event.data;
      if (message.verificationIframeUid !== this.verificationIframeUid) {
        return;
      }

      switch (message.action) {
        case "updateHeight":
          this.iframe.style.height = `${message.height}px`;
          break;
        default:
          logger.error("Unsupported message.action");
      }
    });
  }

  init() {
    this.containerElement.appendChild(this.iframe);
  }
}

export default Iframe;
