import React from "react";
import { produce } from "immer";
import { FormattedHTMLMessage } from "react-intl";

import { logger } from "../../lib/utils/logger/logger";
import {
  VerificationService,
  StudentPersonalInfoViewModel,
  SSOViewModel,
  SSOResponse,
} from "../../lib/types/types";
import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { setTabRef, closeTabRef, getTabRef } from "../../lib/refs/refs";

import { LogoComponent } from "../LogoComponent/LogoComponent";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { getLogoUrl } from "../../lib/ProgramTheme/programThemeGetters";

const defaultSubtitle = "Please sign in to your school's web portal. It will open in a new tab.";

const defaultSubtitle2 = "Can't sign in to your school?";

interface StepSSOProps {
  verificationService: VerificationService;
}

const StepSSOComponent = ({ verificationService }: StepSSOProps) => {
  const { programId } = verificationService;
  const viewModel = verificationService.viewModel as SSOViewModel;
  const previousViewModel =
    (verificationService.previousViewModel as StudentPersonalInfoViewModel) || undefined;
  const verificationResponse = verificationService.verificationResponse as SSOResponse;
  const previousVerificationResponse =
    verificationService.previousVerificationResponse || undefined;
  const { verificationId } = verificationService.verificationResponse;
  const hasLogo = !!getLogoUrl(verificationService.programTheme);
  let timeout;

  const pollForVerificationServiceUpdates = () =>
    setTimeout(() => {
      verificationService.fetchExistingVerificationRequest(
        programId,
        verificationId,
        previousVerificationResponse,
      );
    }, 1000);

  const updateViewModel = (key, value) => {
    const nextState = produce(viewModel, (draft) => {
      (draft[key] as any) = value;
    });
    verificationService.updateViewModel(nextState);
  };

  const cancelSSOStep = () => {
    closeTabRef();
    const nextState = produce(viewModel, () => {});
    logger.info("StepSSOComponent submitting cancel request");
    clearTimeout(timeout);
    verificationService.submitStep(
      VerificationStepsEnum.sso,
      nextState,
      verificationService.verificationResponse,
    );
  };

  const updateViewModelAndOpenLoginTab = (key, value) => {
    updateViewModel(key, value);
    openLoginTab();
  };

  const openLoginTab = () => {
    setTabRef(window.open(verificationResponse.loginUrl, "_blank"));
    getTabRef().focus();
  };

  if (viewModel.isSSOActive) {
    timeout = pollForVerificationServiceUpdates();
  }

  if (!viewModel.countryChoice && previousViewModel && previousViewModel.countryChoice) {
    updateViewModel("countryChoice", previousViewModel.countryChoice);
  }

  return (
    <div id="sid-step-sso" className="sid-l-container">
      {viewModel.isSSOActive ? (
        <div className="sid-header sid-l-horz-center">
          <div className="sid-l-horz-center">
            {hasLogo ? (
              <LogoComponent verificationService={verificationService} />
            ) : (
              <img
                className="sid-l-lead-image"
                alt="design element"
                src="https://s3.amazonaws.com/com.sheerid.resources/common/images/2018/icons/doc-pending.svg"
              />
            )}
          </div>
          <div className="sid-header__title sid-l-horz-center sid-l-space-top-md">
            <FormattedHTMLMessage
              id="step.sso.title"
              defaultMessage="Verify using your school credentials"
            />
          </div>
          <div className="sid-header__subtitle-one">
            <FormattedHTMLMessage id="step.sso.subtitle" defaultMessage={defaultSubtitle} />
          </div>
          <div className="sid-l-space-top-xl" />
          <div className="sid-header__subtitle-two sid-h-small-text sid-l-space-top-md">
            <FormattedHTMLMessage id="step.sso.subtitle2" defaultMessage={defaultSubtitle2} />
            <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
              <button
                onClick={cancelSSOStep}
                type="submit"
                className="sid-btn sid-btn--dark sid-l-full-width"
                aria-label="submit"
              >
                <FormattedHTMLMessage
                  id="step.sso.cancel"
                  defaultMessage="Upload Proof of Enrollment"
                />
              </button>
            </div>
          </div>
          <div className="sid-l-space-top-xl" />
        </div>
      ) : (
        <div className="sid-header sid-l-horz-center">
          <div className="sid-l-horz-center">
            {hasLogo ? (
              <LogoComponent verificationService={verificationService} />
            ) : (
              <img
                className="sid-l-lead-image"
                alt="design element"
                src="https://s3.amazonaws.com/com.sheerid.resources/common/images/2018/icons/sso-step.svg"
              />
            )}
          </div>
          <div className="sid-header__title sid-l-horz-center sid-l-space-top-md">
            <FormattedHTMLMessage
              id="step.sso.title"
              defaultMessage="Verify using your school credentials"
            />
          </div>
          <div className="sid-header__subtitle-one">
            <FormattedHTMLMessage id="step.sso.subtitle" defaultMessage={defaultSubtitle} />
          </div>
          <div className="sid-l-space-top-xl" />
          <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
            <button
              id="sid-submit-btn-sso"
              onClick={() => updateViewModelAndOpenLoginTab("isSSOActive", true)}
              type="submit"
              className="sid-btn sid-btn--dark sid-l-full-width"
              aria-label="submit"
            >
              <FormattedHTMLMessage id="step.sso.login" defaultMessage="Sign in to my school" />
            </button>
          </div>
          <div className="sid-l-space-top-xl" />
        </div>
      )}

      <FormFooter verificationService={verificationService} />
    </div>
  );
};

export { StepSSOComponent };
