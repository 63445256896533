import React from "react";
import { RequestOrganizationService } from "../../lib/types/types";
import { RequestOrganizationErrorComponent } from "./RequestOrganizationErrorComponent";
import { RequestOrganizationSuccessComponent } from "./RequestOrganizationSuccessComponent";
import { RequestOrganizationSearchComponent } from "./RequestOrganizationSearchComponent";
import { LoadingScreenComponent } from "../LoadingScreen/LoadingScreenComponent";
import { getOverridenComponent } from "../../lib/componentOverride/componentOverride";

interface RequestOrganizationLoadStepComponentProps {
  requestOrganizationService: RequestOrganizationService;
}

export const RequestOrganizationLoadStepComponent = ({
  requestOrganizationService,
}: RequestOrganizationLoadStepComponentProps) => {
  const { error } = requestOrganizationService;
  const { submitted } = requestOrganizationService;
  const { isLoading } = requestOrganizationService;
  let OverriddenComponent;

  if (isLoading) {
    return <LoadingScreenComponent />;
  }
  if (error) {
    OverriddenComponent = getOverridenComponent("RequestOrganizationErrorComponent");
    if (OverriddenComponent) {
      return <OverriddenComponent requestOrganizationService={requestOrganizationService} />;
    }
    return (
      <RequestOrganizationErrorComponent requestOrganizationService={requestOrganizationService} />
    );
  }
  if (submitted) {
    OverriddenComponent = getOverridenComponent("RequestOrganizationSuccessComponent");
    if (OverriddenComponent) {
      return <OverriddenComponent requestOrganizationService={requestOrganizationService} />;
    }
    return (
      <RequestOrganizationSuccessComponent
        requestOrganizationService={requestOrganizationService}
      />
    );
  }

  OverriddenComponent = getOverridenComponent("RequestOrganizationSearchComponent");
  if (OverriddenComponent) {
    return <OverriddenComponent />;
  }
  return <RequestOrganizationSearchComponent />;
};
