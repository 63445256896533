/**
 * Wrap a button or link with copy-to-clipboard behavior.
 *
 * Example Use:
    <CopyToClipboard textToCopy={htmlSource} notificationText={<FormattedMessage id="copied" />}>
        <LinkButton ... />
    </CopyToClipboard>
 */
import React from "react";
import copyToClipboard from "copy-to-clipboard";

interface Props {
  textToCopy: string;
  notificationTimeout?: number;
  notificationText?: string | JSX.Element;
}

interface State {
  isShowing: boolean;
}

const twoSeconds = 2000;

const classNameHidden =
  "sid-to-clipboard__notification-text sid-to-clipboard__notification-text--hidden";
const classNameVisible =
  "sid-to-clipboard__notification-text sid-to-clipboard__notification-text--visible";

class CopyToClipboard extends React.Component<Props, State> {
  notificationTimeout: number;

  timeoutRef: any; // VS Code wants "Timer" but tsc wants "number". TODO - figure out a fix to be more specific here.

  constructor(props: any) {
    super(props);
    this.doCopy = this.doCopy.bind(this);
    this.state = { isShowing: false };
    if (props.notificationTimeout) {
      this.notificationTimeout = props.notificationTimeout;
    } else {
      this.notificationTimeout = twoSeconds;
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutRef);
  }

  doCopy() {
    const { textToCopy } = this.props;
    copyToClipboard(textToCopy);
    this.setState(() => ({ isShowing: true }));
    clearTimeout(this.timeoutRef);
    this.timeoutRef = setTimeout(() => {
      this.setState(() => ({ isShowing: false }));
    }, this.notificationTimeout);
  }

  render() {
    const { isShowing } = this.state;
    const { notificationText, children } = this.props;
    return (
      <div className="sid-to-clipboard">
        <div className={`${isShowing ? classNameVisible : classNameHidden}`}>
          {notificationText || "Copied"}
        </div>
        <div
          onClick={this.doCopy}
          onKeyPress={this.doCopy}
          role="button"
          tabIndex={0}
          className="sid-h-link-like sid-to-clipboard__clickable-text sid-link"
        >
          {children || "Copy"}
        </div>
      </div>
    );
  }
}

export { CopyToClipboard };
