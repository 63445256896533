import React from "react";
import { FormattedHTMLMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { FormSelectChoice, InputSelectComponentProps } from "../../../lib/types/types";
import { SelectComponent } from "../../FormInputs/Select/SelectComponent";

const ChangeLocale = ({
  value,
  options,
  onChange,
  intl,
}: InputSelectComponentProps & InjectedIntlProps) => {
  const newOptions: FormSelectChoice[] = options.map((option) => ({
    value: option.value,
    label: intl.formatMessage({ id: `locales.${option.value}`, defaultMessage: "Label not found" }),
  }));

  return (
    <div className="sid-change-locale">
      <div className="sid-field__label sid-change-locale__label ">
        <FormattedHTMLMessage id="changeLanguage" defaultMessage="Change language" />
      </div>
      <SelectComponent
        className="sid-change-locale__select"
        hideDropDownButton
        options={newOptions}
        onChange={(locale: FormSelectChoice) => onChange(locale)}
        overrideInputClassName="sid-change-locale__input"
        value={{
          value: value.value,
          label: intl.formatMessage({
            id: `locales.${value.value}`,
            defaultMessage: "Label not found",
          }),
        }}
      />
      <div className="sid-l-space-btm-sm" />
    </div>
  );
};

export const ChangeLocaleComponent = injectIntl(ChangeLocale);
