import React from "react";
import { FormWrapperComponent as FormWrapper } from "../FormWrapper/FormWrapperComponent";
import {
  DatabaseId,
  RequestOrganizationService,
  RequestOrganizationViewModel,
} from "../../lib/types/types";
import { assertValidProgramId } from "../../lib/types/assertions";
import {
  RequestOrganizationStore,
  RequestOrganizationContext,
} from "../../lib/RequestOrganizationService/RequestOrganizationStore";
import { NewRequestOrganizationFormComponent } from "./NewRequestOrganizationComponent";

interface RequestOrganizationFormComponentProps {
  programId: DatabaseId;
  viewModel?: RequestOrganizationViewModel; // eslint-disable-line
}

export const RequestOrganizationForm = ({
  programId,
  viewModel,
}: RequestOrganizationFormComponentProps) => {
  assertValidProgramId(programId);
  const [, dispatch]: [RequestOrganizationService, Function] = React.useContext(
    RequestOrganizationContext,
  );
  dispatch({ type: "PROGRAM_ID", payload: programId });

  return (
    <RequestOrganizationStore>
      <FormWrapper>
        <NewRequestOrganizationFormComponent programId={programId} viewModel={viewModel} />
      </FormWrapper>
    </RequestOrganizationStore>
  );
};
