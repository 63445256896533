import Cookies from "js-cookie";
import { Conversion, ConversionRequest, DatabaseId } from "../types/types";
import { convertByVerificationId, convertByTrackingId } from "../ServerApi/ConversionApiClient";
import { assertValidTrackingId } from "../types/assertions";
import { getTrackingIdFromQueryString } from "../utils/routing/Url";
import { listenToSheerIdFrame, speakToOuterFrame } from "../frames/frameMessages";
import { logger } from "../utils/logger/logger";
import { getOptions } from "../../options/options";

const VERIFICATION_ID_COOKIE_NAME = "sid-verificationId";
const FRAME_MSG_PROPERTY = "verificationId";

/**
 * @description attempts to record a conversion with SheerID's REST API using a previously-set verificationId (set in a cookie)
 */
export const convert = (conversionRequest: ConversionRequest): void => {
  let verificationId: DatabaseId;
  try {
    verificationId = Cookies.get(VERIFICATION_ID_COOKIE_NAME);

    if (verificationId) {
      convertByVerificationId(verificationId, conversionRequest);
    } else {
      logger.info("verificationId not found from cookie");
    }
  } catch (e) {
    logger.error(e, "Conversion error");
  }
};

/**
 * Begin a conversion session recording.
 * Saves verificationId as a cookie for use later.
 * This should be called by the page that has the SheerID <iframe>, not the page that renders the verification form.
 */
export const listenForVerificationId = () => {
  logger.info("Listening for verificationId");
  listenToSheerIdFrame(onSheerIdFrameMessage);
};

/**
 * @private
 * @todo Note we are _only_ using JSON serialize/parse because https://services.sheerid.com/jsapi/SheerID.js
 *  breaks if it cannot parse a json frame message.
 */
export const onSheerIdFrameMessage = (event) => {
  if (
    event &&
    event.data &&
    typeof event.data === "object" &&
    event.data.hasOwnProperty(FRAME_MSG_PROPERTY)
  ) {
    const verificationId = event.data[FRAME_MSG_PROPERTY];
    storeConversionVerificationIdCookie(verificationId);
  }
};

/**
 * @private
 */
export const storeConversionVerificationIdCookie = (verificationId: DatabaseId): void => {
  const cookieOptions = getOptions().cookies;

  Cookies.remove(VERIFICATION_ID_COOKIE_NAME, cookieOptions);

  if (cookieOptions.enabled) {
    Cookies.set(VERIFICATION_ID_COOKIE_NAME, verificationId, cookieOptions);
    logger.info(`Set cookie ${VERIFICATION_ID_COOKIE_NAME} with value ${verificationId}`);
  } else {
    logger.info("Cookies disabled");
  }
};

/**
 * @private
 */
export const saveVerificationIdForConversion = (verificationId: DatabaseId): void => {
  speakToOuterFrame({ [FRAME_MSG_PROPERTY]: verificationId });
  // also store on *.sheerid.com, for users who are _not_ using an iFrame
  storeConversionVerificationIdCookie(verificationId);
};

/**
 * @private
 */
export const resolveTrackingId = (trackingId: string = undefined): string => {
  let resolvedTrackingId;

  if (trackingId !== undefined) {
    // trackingId is optional, but if it is provided directly, assert that it's valid.
    resolvedTrackingId = trackingId;
    assertValidTrackingId(trackingId);
  } else {
    // If reading from URL, we should be more forgiving and not error-out if trackingId is invalid.
    const windowQueryString = window.location.search;
    resolvedTrackingId = getTrackingIdFromQueryString(windowQueryString);
  }

  return resolvedTrackingId;
};

/**
 * The public export of conversion-related fuqnctions
 */
export const conversion: Conversion = {
  convert,
  convertByVerificationId,
  convertByTrackingId,
  listenForVerificationId,
};
