import { LogLevel, ReduxState, VerificationStore } from "../../types/types";
import { getSafe } from "../objects";

let logLevel = 4; // default
let prefix = "";
const logLevels = {
  info: 1,
  log: 2,
  warn: 3,
  error: 4,
};

const colors = {
  info: "#26c1db",
  log: "#09f979",
  warn: "#f6b13f",
  error: "#e12046",
};

const standardStyles = "color: white; font-weight: bold; padding: 2px 10px;";

let reduxStore;

const logAPIResponseTime = (url: string, time: number) => {
  if (window.NREUM) {
    window.NREUM.addPageAction("API-calls-timing", {
      api_call: url,
      api_response_time: time,
    });
  } else {
    warn("Off-site logging not available.");
  }
};

const isError = (e) => e && e.stack && e.message;

/**
 * @param error Primary, details error message or full error object
 * @param errorMessageGroup Use this to group error messages together with a string that does not vary as frequently.
 *      Do not pass programId, verificationId, etc
 */
const error = (err: Error | string, errorMessageGroup?: string) => {
  if (logLevel <= 4) {
    if (!err) {
      console.error(new Error("An error must be supplied"));
      return;
    }

    let errorObj;
    if (typeof err === "string") errorObj = new Error(err);
    if (isError(err)) errorObj = err;
    if (!errorObj) errorObj = new Error("Unknown error");

    let usefulAttributes: object = { errorMessageGroup };
    try {
      if (reduxStore && reduxStore.getState) {
        const state: ReduxState = reduxStore.getState();
        const errorIds = getSafe(() => state.verificationResponse.errorIds);
        usefulAttributes = {
          errorMessageGroup,
          programId: getSafe(() => state.programId),
          isLoading: getSafe(() => state.isLoading),
          isErrored: getSafe(() => state.isErrored),
          errorIdsFromVerRsp: Array.isArray(errorIds) ? errorIds.join : undefined,
          verificationId: getSafe(() => state.verificationResponse.verificationId),
          currentStep: getSafe(() => state.verificationResponse.currentStep),
          locale: getSafe(() => state.programTheme.intl.locale),
          isTestMode: getSafe(() => state.programTheme.isTestMode),
          openOrgSearchEnabled: getSafe(() => state.programTheme.openOrgSearchEnabled),
          jslibVerActual: getSafe(() => window.sheerIdPubV, "?"),
          jslibVerRequested: getSafe(() => window.sheerIdReqV, "?"),
        };
      }
    } catch (e) {
      // Some invokers of logger.error() don't use redux
      console.warn("Unable to assemble useful error attributes", e); // eslint-disable-line
    }
    try {
      if (window.NREUM) {
        window.NREUM.noticeError(errorObj, usefulAttributes);
      } else {
        // Some customers can't install New Relic so this is not an error:
        warn("Off-site logging not available."); // eslint-disable-line
      }
    } catch (e) {
      console.error("Unable to send error to remote service", e); // eslint-disable-line
    }
    console.error(
      `%c${prefix} error`,
      `background: ${colors.error};${standardStyles}`,
      errorObj,
      usefulAttributes,
    ); // eslint-disable-line
  }
};

const warn = (...args) => {
  if (logLevel <= 3) {
    console.warn(`%c${prefix} warn`, `background: ${colors.warn};${standardStyles}`, ...args); // eslint-disable-line
  }
};

const log = (...args) => {
  if (logLevel <= 2) {
    console.log(`%c${prefix} log`, `background: ${colors.log};${standardStyles}`, ...args); // eslint-disable-line
  }
};

const info = (...args) => {
  if (logLevel <= 1) {
    console.log(`%c${prefix} info`, `background: ${colors.info};${standardStyles}`, ...args); // eslint-disable-line
  }
};

export const logger = {
  error,
  warn,
  log,
  info,
  logAPIResponseTime,
  setLogLevel: (desiredLogLevel: LogLevel) => {
    if (!logLevels.hasOwnProperty(desiredLogLevel)) {
      throw new Error(`Unknown logLevel '${desiredLogLevel}'`);
    }

    logLevel = logLevels[desiredLogLevel];
    console.log(
      `%c${prefix} log level set to ${desiredLogLevel}`,
      `background: ${colors[desiredLogLevel]};${standardStyles}`,
    );
  },
  setPrefix: (thisPrefix: string) => {
    prefix = thisPrefix;
  },
  init: (store: VerificationStore) => {
    reduxStore = store;
  },
};
