import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import {
  FormSelectChoice,
  FormFieldComponentProps,
  VerificationServiceProps,
  Organization,
  ActiveMilitaryPersonalInfoViewModel,
} from "../../../lib/types/types";
import { InputSelectComponent } from "../../FormInputs/InputSelect/InputSelectComponent";
import { SortByLabel } from "../../../lib/utils/structures/FormSelectChoice";
import { orgsInStatus, orgToOption } from "../../StepMilitaryPersonalInfo/militaryStepHelpers";
import { FieldLabel } from "../../FieldLabel";

interface BranchOfServiceComponentProps {
  organizations: Organization[];
}

export const BranchOfService = ({
  autoFocus,
  onChange,
  value,
  isErrored,
  organizations,
  verificationService,
  intl,
  placeholder = "",
}: FormFieldComponentProps & VerificationServiceProps & BranchOfServiceComponentProps) => {
  let options: FormSelectChoice[] = [];
  const { status } = verificationService.viewModel as ActiveMilitaryPersonalInfoViewModel;
  const inputId = "sid-branch-of-service";

  if (organizations) options = organizations.filter(orgsInStatus(status)).map(orgToOption);

  const handleStateChange = (selectedOrgOption: FormSelectChoice, onChange: Function) => {
    if (selectedOrgOption) {
      onChange({
        id: selectedOrgOption.value,
        name: selectedOrgOption.label,
      });
    } else {
      onChange({
        id: 0,
        name: "",
      });
    }
  };

  return (
    <div className={`sid-field ${inputId}-id`}>
      <div className="sid-l-space-top-md" />
      <FieldLabel
        text={<FormattedHTMLMessage id="branchOfService" defaultMessage="Branch Of Service" />}
        htmlForLabel={inputId}
        id={`${inputId}-label`}
        displayClasses={placeholder && "sid-h-screen-reader-only"}
      />

      <InputSelectComponent
        autoFocus={autoFocus}
        fieldId="organization"
        inputId={inputId}
        fieldLabelId={`${inputId}-label`}
        isErrored={isErrored}
        options={SortByLabel(options)}
        onChange={(branch: FormSelectChoice) => handleStateChange(branch, onChange)}
        value={value}
        placeholder={
          placeholder ||
          intl.formatMessage({
            id: "branchOfServicePlaceholder",
            defaultMessage: "Branch Of Service*",
          })
        }
      />
      {isErrored ? (
        <div className="sid-field-error">
          <FormattedHTMLMessage
            id="errorId.invalidOrganization"
            defaultMessage="You must select an organization from the list"
          />
        </div>
      ) : null}
    </div>
  );
};

export const BranchOfServiceComponent = injectIntl(BranchOfService);
