/* tslint:disable:max-line-length */
import { SegmentSpecificMessages } from "../../types/types";

export const teacherMessages: SegmentSpecificMessages = {
  emailExplanation: "Must be your school-issued email address",
  schoolName: "School name",
  step: {
    personalInfo: {
      title: "Unlock this Teaching Staff-Only Offer",
      subtitle:
        "Verify you're a teacher, faculty or staff at a K-12 or degree-granting university.",
      howDoesVerifyingWorkDetails: `
            To be eligible for this offer, you must currently be employed as a teacher, faculty or staff at a K-12
            school or university in the United States.
            <br><br>
            {companyName} uses SheerID, a trusted partner, to verify that you are a current teacher, faculty or staff
            member. SheerID only collects information that assists in verifying your eligibility. Additional information
            or documentation may be requested.`,
      tryAgain: {
        title: "Check Your Name & Email",
        subtitle: "Your full name and email address must match what is on record with your school.",
      },
      verifyingTitle: "Verifying your teacher status",
    },
    docUpload: {
      title: "We need more information",
      subtitle:
        "Documents will be reviewed in {estimatedWaitTime} by staff at SheerID, a trusted partner.",
      howDoesVerifyingWorkDetails: `Document review is handled by SheerID, a trusted partner who specializes in verifying teachers.
                <br><br>
                Documents are used only for verifying your teacher or faculty status is current and will be permanently
                deleted after review is finalized. They will not be shared with {companyName}`,
      uploadInstructions: "Upload your school-issued document that shows",
    },
    success: {
      title: "You've been verified",
      subtitle: "Here is your personal coupon code. It is unique to you and can only be used once.",
      redirectButtonText: "Use code now",
    },
  },
};
