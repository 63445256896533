/**
 * TODO - preamble
 */
import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { produce } from "immer";

import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { isFormFilled } from "../../lib/validators/validators";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";
import { setRef } from "../../lib/refs/refs";
import {
  handleEmailOnKeyDown,
  submitForm,
  updateFieldValidationErrorsByFieldId,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";
import {
  VerificationService,
  SeniorPersonalInfoViewModel,
  SeniorPersonalInfoResponse,
} from "../../lib/types/types";

import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from "../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent";
import { LogoComponent } from "../LogoComponent/LogoComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { PostalCodeComponent as PostalCode } from "../FormFields/PostalCode/PostalCodeComponent";
import { RewardsRemainingComponent } from "../RewardsRemaining/RewardsRemainingComponent";

interface StepSeniorPersonalInfoComponentProps {
  verificationService: VerificationService;
}

const StepSeniorPersonalInfo = ({ verificationService }: StepSeniorPersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as SeniorPersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse =
    verificationService.verificationResponse as SeniorPersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

  const updateSeniorViewModel = (key: keyof SeniorPersonalInfoViewModel, value: any) => {
    const nextState: SeniorPersonalInfoViewModel = produce(
      viewModel,
      (draft: SeniorPersonalInfoViewModel) => {
        (draft[key] as any) = value;
      },
    );
    verificationService.updateViewModel(nextState);
  };

  return (
    <div id="sid-step-student-personal-info" className="sid-l-container">
      {failedInstantMatch ? (
        <div className="sid-header">
          <div className="sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.tryAgain.title"
              defaultMessage="Check Your Name & Birthdate"
            />
          </div>
          <div className="sid-header__subtitle sid-l-horz-center sid-header__subtitle--error">
            <FormattedHTMLMessage
              id="step.personalInfo.tryAgain.subtitle"
              defaultMessage="Your full name and birthdate must match what is on your government-issued documents."
            />
          </div>
        </div>
      ) : (
        <div className="sid-header">
          <div className="sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.title"
              defaultMessage="Unlock Your Special Offer"
              tagName="h1"
            />
          </div>
          <div className="sid-header__subtitle sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.subtitle"
              defaultMessage="Verify your current age."
            />
            &nbsp;
            <HowDoesVerifyingWork verificationService={verificationService} />
            <RewardsRemainingComponent verificationService={verificationService} />
          </div>
        </div>
      )}
      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateSeniorViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />

        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateSeniorViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>

      <BirthDate
        isErrored={!!fieldValidationErrors.birthDate}
        errorId={fieldValidationErrors.birthDate}
        onChange={(newValue) => {
          updateSeniorViewModel("birthDate", newValue);
          updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
        }}
        value={viewModel.birthDate}
      />

      <PostalCode
        isErrored={!!fieldValidationErrors.postalCode}
        onChange={(newValue) => {
          updateSeniorViewModel("postalCode", newValue);
          updateFieldValidationErrorsByFieldId("postalCode", newValue, verificationService);
        }}
        value={viewModel.postalCode}
      />

      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        onChange={(newValue) => {
          updateSeniorViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />
      {verificationService.programTheme.isSmsNotifierConfigured ||
      verificationService.programTheme.smsLoopEnabled ? (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updateSeniorViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      ) : null}
      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateSeniorViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
        <div className="sid-submit">
          <button
            id="sid-submit-btn-collect-info"
            onClick={() =>
              submitForm(
                viewModel,
                verificationService,
                VerificationStepsEnum.collectSeniorPersonalInfo,
              )
            }
            type="submit"
            className={`sid-btn sid-btn--dark sid-l-full-width ${
              !isFormFilled(viewModel, verificationService.formValidationOptions)
                ? "sid-btn--disabled-like"
                : ""
            }`}
            aria-labelledby="verify-status-text"
            ref={(button) => setRef("submitButton", button)}
          >
            <span id="verify-status-text">
              {failedInstantMatch ? (
                <FormattedHTMLMessage id="tryAgain" defaultMessage="Try Again" />
              ) : (
                <FormattedHTMLMessage id="verifyAndContinue" defaultMessage="Verify and continue" />
              )}
            </span>
          </button>
        </div>
      </div>

      <FormFooter verificationService={verificationService} />
    </div>
  );
};

// TODO remove injectIntl, not used
export const StepSeniorPersonalInfoComponent = injectIntl(StepSeniorPersonalInfo);
