/* tslint:disable:max-line-length */
import { SegmentSpecificMessages } from "../../types/types";

export const firstResponderMessages: SegmentSpecificMessages = {
  emailExplanation: "Needed to send you your unique code",
  step: {
    personalInfo: {
      title: "Unlock this 1st Responder-Only Offer",
      subtitle: "Verify you're an active first responder.",
      howDoesVerifyingWorkDetails: `
            This offer is available to all US first responders and emergency personnel - including law enforcement,
            EMTs, paramedics, and firefighters.
            <br><br>
            {companyName} uses SheerID, a trusted partner, to verify that you are an active first responder. SheerID
            only collects information that assists in verifying your eligibility. Additional information or documentation may be requested.`,
      tryAgain: {
        title: "Check Your Name, Email & Organization",
        subtitle:
          "Your full name, organization name, and email must match what is on record with your agency.",
      },
      verifyingTitle: "Verifying your first responder status",
    },
    docUpload: {
      title: "We need your ID",
      subtitle:
        "Documents will be reviewed in {estimatedWaitTime} by staff at SheerID, a trusted partner.",
      howDoesVerifyingWorkDetails: `Document review is handled by SheerID, a trusted partner who specializes in verifying first responders.
            <br><br>
            Documents are used only for verifying your first responder status is current and will be permanently deleted after review is finalized.
            They will not be shared with {companyName}`,
      uploadInstructions: "Upload an official document that shows",
    },
    success: {
      title: "You've been verified",
      subtitle: "Here is your personal coupon code. It is unique to you and can only be used once.",
      redirectButtonText: "Use code now",
    },
  },
};
