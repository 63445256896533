import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import {
  FormSelectChoice,
  FormFieldComponentProps,
  VerificationServiceProps,
  Country,
} from "../../../lib/types/types";
import { TypeaheadComponent } from "../../FormInputs/Typeahead/TypeaheadComponent";
import { getSafe } from "../../../lib/utils/objects";
import { getOptions } from "../../../options/options";
import { FieldLabel } from "../../FieldLabel";

export const FirstResponderOrganization = ({
  onChange,
  value,
  isErrored,
  verificationService,
  intl,
  placeholder = "",
}: FormFieldComponentProps & VerificationServiceProps) => {
  const { programId } = verificationService;

  return (
    <div className="sid-field sid-first-responder-organization-id">
      <div className="sid-l-space-top-md" />
      <FieldLabel
        text={<FormattedHTMLMessage id="organization" defaultMessage="Organization" />}
        htmlForLabel="sid-first-responder-organization"
        id="sid-first-responder-organization-label"
        displayClasses={placeholder && "sid-h-screen-reader-only"}
      />

      <TypeaheadComponent
        className={isErrored ? "sid-text-input--error" : ""}
        countryCode={getSafe<Country>(() => verificationService.viewModel.countryChoice.value)}
        minimumSearchValueLength={getOptions().minimumOrganizationSearchLength}
        onChange={(choice: FormSelectChoice) => onChange(choice)}
        placeholder={
          placeholder ||
          intl.formatMessage({ id: "organizationPlaceholder", defaultMessage: "Organization*" })
        }
        programId={programId}
        value={value}
        inputHtmlId="sid-first-responder-organization"
        openOrgSearchEnabled={verificationService.programTheme.openOrgSearchEnabled}
        orgSearchUrl={verificationService.programTheme.config.orgSearchUrl}
      />
      {isErrored ? (
        <div className="sid-field-error">
          <FormattedHTMLMessage
            id="errorId.invalidOrganization"
            defaultMessage="You must select an organization from the list"
          />
        </div>
      ) : null}
    </div>
  );
};

export const FirstResponderOrganizationComponent = injectIntl(FirstResponderOrganization);
