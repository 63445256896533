/**
 * TODO - preamble
 */
import React from "react";
import { FormattedHTMLMessage, injectIntl, InjectedIntl } from "react-intl";
import { produce } from "immer";

import {
  VerificationService,
  StudentPersonalInfoViewModel,
  Organization,
  FormSelectChoice,
  StudentPersonalInfoResponse,
  Locale,
} from "../../lib/types/types";

import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";
import { isFormFilled } from "../../lib/validators/validators";
import { setRef } from "../../lib/refs/refs";
import {
  handleEmailOnKeyDown,
  submitForm,
  updateFieldValidationErrorsByFieldId,
  updateViewModelOrganization,
  getAvailableLocaleChoices,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";

import { ChangeLocaleComponent } from "../FormFields/ChangeLocale/ChangeLocaleComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { LogoComponent } from "../LogoComponent/LogoComponent";
import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { CollegeNameComponent as CollegeName } from "../FormFields/CollegeName/CollegeNameComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from "../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent";
import { CountryComponentWrapper } from "../FormFields/Country/CountryComponentWrapper";
import { RewardsRemainingComponent } from "../RewardsRemaining/RewardsRemainingComponent";

interface StepStudentPersonalInfoComponentProps {
  intl: InjectedIntl;
  verificationService: VerificationService;
}

const StepStudentPersonalInfo = ({
  intl,
  verificationService,
}: StepStudentPersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as StudentPersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const localeChoices: FormSelectChoice<Locale, string>[] = getAvailableLocaleChoices(
    verificationService.programTheme,
    intl,
  );
  const defaultLocaleChoice: FormSelectChoice<Locale, string> = {
    value: "en-US",
    label: "English",
  };
  const verificationResponse =
    verificationService.verificationResponse as StudentPersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

  const updateStudentViewModel = (key: keyof StudentPersonalInfoViewModel, value: any) => {
    const nextState: StudentPersonalInfoViewModel = produce(
      viewModel,
      (draft: StudentPersonalInfoViewModel) => {
        (draft[key] as any) = value;
      },
    );
    verificationService.updateViewModel(nextState);
  };

  const updateLocale = (localeChoice: FormSelectChoice<Locale, string>) => {
    const nextState: StudentPersonalInfoViewModel = produce(
      viewModel,
      (draft: StudentPersonalInfoViewModel) => {
        draft.localeChoice = localeChoice;
      },
    );
    verificationService.updateLocale(
      nextState,
      verificationService.programTheme,
      verificationService.verificationResponse.segment,
    );
  };

  return (
    <div id="sid-step-student-personal-info" className="sid-l-container">
      {failedInstantMatch ? (
        <div className="sid-header">
          <div className="sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.tryAgain.title"
              defaultMessage="Check Your Name & Birthdate"
            />
          </div>

          <div className="sid-header__subtitle sid-l-horz-center sid-header__subtitle--error">
            <FormattedHTMLMessage
              id="step.personalInfo.tryAgain.subtitle"
              defaultMessage="Your full name and birthdate must match what is on your official school records."
            />
          </div>
        </div>
      ) : (
        <div className="sid-header">
          <div className="sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.title"
              defaultMessage="Unlock this Student-Only Offer"
              tagName="h1"
            />
          </div>

          <div className="sid-header__subtitle sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.subtitle"
              defaultMessage="Verify you're a student at a degree-granting school."
            />
            &nbsp;
            <HowDoesVerifyingWork verificationService={verificationService} />
            <RewardsRemainingComponent verificationService={verificationService} />
          </div>
        </div>
      )}

      {localeChoices.length > 1 ? (
        <ChangeLocaleComponent
          options={localeChoices}
          value={viewModel.localeChoice || defaultLocaleChoice}
          isErrored={false}
          onChange={(localeChoice: FormSelectChoice<Locale, string>) => updateLocale(localeChoice)}
        />
      ) : null}

      <CountryComponentWrapper verificationService={verificationService} viewModel={viewModel} />

      <CollegeName
        value={(verificationService.viewModel as StudentPersonalInfoViewModel).organization}
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.organization}
        isRequired
        onChange={(choice: Organization) => {
          updateViewModelOrganization(choice, verificationService);
          updateFieldValidationErrorsByFieldId("organization", choice, verificationService);
        }}
      />

      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateStudentViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />

        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateStudentViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>

      <BirthDate
        value={viewModel.birthDate}
        isErrored={!!fieldValidationErrors.birthDate}
        errorId={fieldValidationErrors.birthDate}
        isRequired
        onChange={(newValue) => {
          updateStudentViewModel("birthDate", newValue);
          updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
        }}
      />

      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        explanation={
          <FormattedHTMLMessage
            id="emailExplanation"
            defaultMessage="Personal or school email is fine"
          />
        }
        onChange={(newValue) => {
          updateStudentViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />

      {verificationService.programTheme.isSmsNotifierConfigured ||
      verificationService.programTheme.smsLoopEnabled ? (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updateStudentViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      ) : null}

      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateStudentViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
        <div className="sid-submit">
          <button
            id="sid-submit-wrapper__collect-info"
            onClick={() =>
              submitForm(
                viewModel,
                verificationService,
                VerificationStepsEnum.collectStudentPersonalInfo,
              )
            }
            type="submit"
            className={`sid-btn sid-btn--dark sid-l-full-width ${
              !isFormFilled(viewModel, verificationService.formValidationOptions)
                ? "sid-btn--disabled-like"
                : ""
            }`}
            aria-label="submit"
            aria-labelledby="verify-status-text"
            ref={(button) => setRef("submitButton", button)}
          >
            <span id="verify-status-text">
              {failedInstantMatch ? (
                <FormattedHTMLMessage id="tryAgain" defaultMessage="Try Again" />
              ) : (
                <FormattedHTMLMessage
                  id="verifyMyStudentStatus"
                  defaultMessage="Verify My Student Status"
                />
              )}
            </span>
          </button>
        </div>
      </div>

      <FormFooter verificationService={verificationService} />
    </div>
  );
};

export const StepStudentPersonalInfoComponent = injectIntl(StepStudentPersonalInfo);
