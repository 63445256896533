import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FieldLabel } from "../../FieldLabel";
import { InputTextComponent } from "../../FormInputs/InputText/InputText";

export const FirstName = ({
  value,
  isErrored,
  onChange,
  intl,
  placeholder = "",
}: FormFieldComponentProps) => (
  <div className="sid-field sid-first-name">
    <div className="sid-l-space-top-md" />
    <FieldLabel
      text={<FormattedHTMLMessage id="firstName" defaultMessage="First name" />}
      htmlForLabel="sid-first-name"
      id="sid-first-name-label"
      displayClasses={placeholder && "sid-h-screen-reader-only"}
    />

    <InputTextComponent
      id="first-name"
      isErrored={isErrored}
      onChange={(e) => onChange(e.target.value)}
      placeholder={
        placeholder ||
        intl.formatMessage({ id: "firstNamePlaceholder", defaultMessage: "First Name*" })
      }
      refId="firstName"
      value={value}
      required
      aria-labelledby="sid-first-name-label"
    />

    {isErrored ? (
      <div className="sid-field-error">
        <FormattedHTMLMessage id="errorId.invalidFirstName" defaultMessage="Invalid first name" />
      </div>
    ) : null}
  </div>
);

export const FirstNameComponent = injectIntl(FirstName);
