import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FieldLabel } from "../../FieldLabel";
import { InputTextComponent } from "../../FormInputs/InputText/InputText";

export const LastName = ({
  value,
  isErrored,
  onChange,
  intl,
  placeholder = "",
}: FormFieldComponentProps) => (
  <div className="sid-field sid-last-name">
    <div className="sid-l-space-top-md" />
    <FieldLabel
      text={<FormattedHTMLMessage id="lastName" defaultMessage="Last name" />}
      htmlForLabel="sid-last-name"
      id="sid-last-name-label"
      displayClasses={placeholder && "sid-h-screen-reader-only"}
    />

    <InputTextComponent
      id="last-name"
      isErrored={isErrored}
      onChange={(event) => onChange(event.target.value)}
      placeholder={
        placeholder ||
        intl.formatMessage({ id: "lastNamePlaceholder", defaultMessage: "Last Name*" })
      }
      refId="lastName"
      value={value}
      required
      aria-labelledby="sid-last-name-label"
    />

    {isErrored ? (
      <div className="sid-field-error">
        <FormattedHTMLMessage id="errorId.invalidLastName" defaultMessage="Invalid last name" />
      </div>
    ) : null}
  </div>
);

export const LastNameComponent = injectIntl(LastName);
