/**
 * TODO - preamble
 */
import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { produce } from "immer";

import { VerificationStepsEnum } from "../../lib/types/runtimeTypes";
import { isFormFilled } from "../../lib/validators/validators";
import { hasFailedInstantMatch } from "../../lib/VerificationService/VerificationServiceHelpers";
import { setRef, resetRefs } from "../../lib/refs/refs";
import {
  VerificationService,
  HybridIdentityPersonalInfoViewModel,
  HybridIdentityPersonalInfoResponse,
  FormSelectChoice,
  State,
} from "../../lib/types/types";
import {
  getAvailableStateChoices,
  handleEmailOnKeyDown,
  submitForm,
  updateFieldValidationErrorsByFieldId,
} from "../../lib/utils/stepComponentHelpers/stepComponentHelpers";

import { HowDoesVerifyingWorkComponent as HowDoesVerifyingWork } from "../HowDoesVerifyingWork/HowDoesVerifyingWorkComponent";
import { LogoComponent } from "../LogoComponent/LogoComponent";
import { PhoneNumberComponent as PhoneNumber } from "../FormFields/PhoneNumber/PhoneNumberComponent";
import { FirstNameComponent as FirstName } from "../FormFields/FirstName/FirstNameComponent";
import { LastNameComponent as LastName } from "../FormFields/LastName/LastNameComponent";
import { EmailComponent as Email } from "../FormFields/Email/EmailComponent";
import { FormFooterComponent as FormFooter } from "../FormFooter/FormFooterComponent";
import { MarketConsentWrapperComponent as MarketConsentWrapper } from "../FormFields/MarketConsentWrapper/MarketConsentWrapperComponent";
import { AddressComponent } from "../FormFields/Address/AddressComponent";
import { CityComponent } from "../FormFields/City/City";
import { PostalCodeComponent } from "../FormFields/PostalCode/PostalCodeComponent";
import { BirthDateComponent as BirthDate } from "../FormFields/BirthDate/BirthDateComponent";
import { DriverLicenseNumberComponent as DriverLicenseNumber } from "../FormFields/DriverLicenseNumber/DriverLicenseNumberComponent";
import { StateSelectComponent } from "../FormFields/State/StateSelectComponent";
import { RewardsRemainingComponent } from "../RewardsRemaining/RewardsRemainingComponent";

interface StepHybridIdentityPersonalInfoComponentProps {
  intl: any;
  verificationService: VerificationService;
}

export const StepHybridIdentityPersonalInfo = ({
  intl,
  verificationService,
}: StepHybridIdentityPersonalInfoComponentProps) => {
  const viewModel = verificationService.viewModel as HybridIdentityPersonalInfoViewModel;
  const { fieldValidationErrors } = verificationService;
  const verificationResponse =
    verificationService.verificationResponse as HybridIdentityPersonalInfoResponse;
  const failedInstantMatch = hasFailedInstantMatch(verificationResponse);

  const updateIdentityViewModel = (key: keyof HybridIdentityPersonalInfoViewModel, value: any) => {
    const nextState: HybridIdentityPersonalInfoViewModel = produce(
      viewModel,
      (draft: HybridIdentityPersonalInfoViewModel) => {
        (draft[key] as any) = value;
      },
    );
    verificationService.updateViewModel(nextState);
  };

  // Refs should be reset to ensure the field order isn't affected by the previous step
  resetRefs();

  return (
    <div id="sid-step-hybrid-identity-personal-info" className="sid-l-container">
      {failedInstantMatch ? (
        <div className="sid-header">
          <div className="sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.tryAgain.title"
              defaultMessage="Check Your Name and other fields"
            />
          </div>

          <div className="sid-header__subtitle sid-l-horz-center sid-header__subtitle--error">
            <FormattedHTMLMessage
              id="step.personalInfo.tryAgain.subtitle"
              defaultMessage="
                                    Enter your first and last name on the form as it appears on your official document(s).
                                    Keep in mind that may be your given name."
            />
          </div>
        </div>
      ) : (
        <div className="sid-header">
          <div className="sid-l-horz-center">
            <LogoComponent verificationService={verificationService} />
          </div>
          <div className="sid-header__title sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.title"
              defaultMessage="Unlock this Special Offer"
              tagName="h1"
            />
          </div>

          <div className="sid-header__subtitle sid-l-horz-center">
            <FormattedHTMLMessage
              id="step.personalInfo.subtitle"
              defaultMessage="Verify your identity"
            />
          </div>
          <div className="sid-header__how-verifying-works sid-l-horz-center">
            <HowDoesVerifyingWork verificationService={verificationService} />
            <RewardsRemainingComponent verificationService={verificationService} />
          </div>
        </div>
      )}

      <div className="sid-names">
        <FirstName
          value={viewModel.firstName}
          isErrored={!!fieldValidationErrors.firstName}
          onChange={(newValue) => {
            updateIdentityViewModel("firstName", newValue);
            updateFieldValidationErrorsByFieldId("firstName", newValue, verificationService);
          }}
        />

        <LastName
          value={viewModel.lastName}
          isErrored={!!fieldValidationErrors.lastName}
          onChange={(newValue) => {
            updateIdentityViewModel("lastName", newValue);
            updateFieldValidationErrorsByFieldId("lastName", newValue, verificationService);
          }}
        />
      </div>

      <Email
        value={viewModel.email}
        isErrored={!!fieldValidationErrors.email}
        explanation={
          <FormattedHTMLMessage
            id="emailExplanation"
            defaultMessage="Personal email address is recommended"
          />
        }
        onChange={(newValue) => {
          updateIdentityViewModel("email", newValue);
          updateFieldValidationErrorsByFieldId("email", newValue, verificationService);
        }}
        onKeyDown={(event) => handleEmailOnKeyDown(event)}
      />
      {verificationService.programTheme.isSmsNotifierConfigured ||
      verificationService.programTheme.smsLoopEnabled ? (
        <PhoneNumber
          isRequired={!!verificationService.programTheme.smsLoopEnabled}
          value={viewModel.phoneNumber}
          isErrored={!!fieldValidationErrors.phoneNumber}
          onChange={(newValue) => {
            updateIdentityViewModel("phoneNumber", newValue);
            updateFieldValidationErrorsByFieldId("phoneNumber", newValue, verificationService);
          }}
          selectedCountryCode={viewModel.countryChoice && viewModel.countryChoice.value}
        />
      ) : null}
      <BirthDate
        isErrored={!!fieldValidationErrors.birthDate}
        errorId={fieldValidationErrors.birthDate}
        onChange={(newValue) => {
          updateIdentityViewModel("birthDate", newValue);
          updateFieldValidationErrorsByFieldId("birthDate", newValue, verificationService);
        }}
        value={viewModel.birthDate}
      />
      <AddressComponent
        isErrored={!!fieldValidationErrors.address1}
        errorId={fieldValidationErrors.address1}
        onChange={(newValue) => {
          updateIdentityViewModel("address1", newValue);
          updateFieldValidationErrorsByFieldId("address1", newValue, verificationService);
        }}
        value={viewModel.address1}
      />
      <div className="sid-thirds">
        <CityComponent
          isErrored={!!fieldValidationErrors.city}
          errorId={fieldValidationErrors.city}
          onChange={(newValue) => {
            updateIdentityViewModel("city", newValue);
            updateFieldValidationErrorsByFieldId("city", newValue, verificationService);
          }}
          value={viewModel.city}
        />
        <StateSelectComponent
          isRequired
          options={getAvailableStateChoices(verificationService.programTheme, intl)}
          value={viewModel.state || undefined}
          isErrored={Boolean(fieldValidationErrors.state)}
          onChange={async (stateChoice: FormSelectChoice<State, string>) => {
            const stateValue = stateChoice ? stateChoice.value : undefined;
            updateIdentityViewModel("state", stateValue);
            updateFieldValidationErrorsByFieldId("state", stateValue, verificationService);
          }}
        />
        <PostalCodeComponent
          isErrored={!!fieldValidationErrors.postalCode}
          onChange={(newValue) => {
            updateIdentityViewModel("postalCode", newValue);
            updateFieldValidationErrorsByFieldId("postalCode", newValue, verificationService);
          }}
          value={viewModel.postalCode}
        />
      </div>
      <DriverLicenseNumber
        value={viewModel.driverLicenseNumber}
        isErrored={!!fieldValidationErrors.driverLicenseNumber}
        isRequired
        onChange={(newValue) => {
          updateIdentityViewModel("driverLicenseNumber", newValue);
          updateFieldValidationErrorsByFieldId(
            "driverLicenseNumber",
            newValue,
            verificationService,
          );
        }}
      />
      <MarketConsentWrapper
        verificationService={verificationService}
        isErrored={!!fieldValidationErrors.marketConsentValue}
        onChange={(newValue) => {
          updateIdentityViewModel("metadata", {
            ...verificationService.viewModel.metadata,
            marketConsentValue: newValue,
          });
          updateFieldValidationErrorsByFieldId("marketConsentValue", newValue, verificationService);
        }}
        viewModel={viewModel}
      />

      <div className="sid-form-region sid-submit-wrapper sid-l-space-top-md">
        <div className="sid-submit">
          <button
            id="sid-submit-btn-collect-info"
            onClick={() =>
              submitForm(
                viewModel,
                verificationService,
                VerificationStepsEnum.collectHybridIdentityPersonalInfo,
              )
            }
            type="submit"
            className={`sid-btn sid-btn--dark sid-l-full-width ${
              !isFormFilled(viewModel, verificationService.formValidationOptions)
                ? "sid-btn--disabled-like"
                : ""
            }`}
            aria-labelledby="verify-status-text"
            ref={(button) => setRef("submitButton", button)}
          >
            <span id="verify-status-text">
              {failedInstantMatch ? (
                <FormattedHTMLMessage id="tryAgain" defaultMessage="Try Again" />
              ) : (
                <FormattedHTMLMessage
                  id="verifyMyIdentityStatus"
                  defaultMessage="Verify My Identity"
                />
              )}
            </span>
          </button>
        </div>
      </div>

      <FormFooter verificationService={verificationService} />
    </div>
  );
};

export const StepHybridIdentityPersonalInfoComponent = injectIntl(StepHybridIdentityPersonalInfo);
