/* tslint:disable:max-line-length */
import { SegmentSpecificMessages } from "../../types/types";

export const moverMessages: SegmentSpecificMessages = {
  emailExplanation: "Needed to send you your unique code",
  step: {
    personalInfo: {
      title: "Unlock this Special Offer",
      subtitle: "Verify you have recently moved.",
      howDoesVerifyingWorkDetails: `
                To be eligible for this offer, you must have moved to a new address within the last 60 days.
                <br><br>
                {companyName} uses SheerID, a trusted partner, to verify that you have recently moved. SheerID only
                collects information that assists in verifying your eligibility.
                Additional information or documentation may be requested.`,
      tryAgain: {
        title: "Check Your Name & Address Information",
        subtitle:
          "Your first and last name and address must match what is on your official documents.",
      },
      verifyingTitle: "Verifying your moving status",
    },
    docUpload: {
      title: "We need some more info",
      subtitle:
        "Documents will be reviewed in {estimatedWaitTime} by staff at SheerID, a trusted partner.",
      howDoesVerifyingWorkDetails: `Document review is handled by SheerID, a trusted partner who specializes in verifying recent movers.
                <br/><br/>
                Documents are used only for verifying that you've recently moved and will will be permanently deleted after review is finalized. They will not be shared with {companyName}.`,
      uploadInstructions: "Upload an official document that shows",
    },
    success: {
      title: "You've been verified",
      subtitle: "Here is your personal coupon code. It is unique to you and can only be used once.",
      redirectButtonText: "Use code now",
    },
  },
};
