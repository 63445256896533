import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { debounce } from "debounce";
import { fetchProgramOrganizations } from "../lib/ServerApi/OrganizationApiClient";
import { OrganizationListComponent } from "./OrganizationList/OrganizationListComponent";
import { OrganizationSearchResp, PropertiesOf, DatabaseId, Locale } from "../lib/types/types";
import { logger } from "../lib/utils/logger/logger";
import { DEFAULT_LOCALE } from "../constants";
import { LinkExternal } from "./LinkExternal";

interface FetchOrganizationsProps {
  countryCode?: string;
  getItemProps: any;
  highlightedIndex: any;
  itemToString: any;
  minimumSearchValueLength: number;
  onLoaded: any;
  programId: DatabaseId;
  searchValue: any;
  orgSearchUrl?: string;
  orgSearchTags?: string;
  locale?: Locale;
  urlAddSchoolForm?: string;
  openOrgSearchEnabled?: boolean;
  getMenuProps?: any; // deprecated. Menu is always mounted now, up in TypeaheadComponent.
}

interface FetchOrganizationState {
  isErrored: string;
  loading: boolean;
  organizations: OrganizationSearchResp;
}

type AddSchoolRequestRowProps = {
  programId: DatabaseId;
  locale: Locale;
  urlAddSchoolForm: string;
};

const AddSchoolRequestRow: React.FC<AddSchoolRequestRowProps> = ({
  urlAddSchoolForm,
  programId,
  locale = DEFAULT_LOCALE,
}: AddSchoolRequestRowProps) => (
  <LinkExternal
    className="sid-organization-list__item sid-organization-list__request-school sid-link"
    href={`${urlAddSchoolForm}?pid=${programId}&locale=${locale}`}
  >
    <FormattedHTMLMessage
      id="requestSchool"
      defaultMessage="Don't see your school? Request to have it added"
    />
  </LinkExternal>
);

class FetchOrganizations extends React.Component<FetchOrganizationsProps, FetchOrganizationState> {
  static initialState: FetchOrganizationState = {
    isErrored: null,
    loading: false,
    organizations: null,
  };

  mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isErrored: null,
      loading: false,
      organizations: null,
    };
  }

  // eslint-disable-next-line react/sort-comp
  fetch = async () => {
    const { orgSearchUrl, searchValue, countryCode, orgSearchTags } = this.props;
    if (!this.mounted) {
      return;
    }
    await fetchProgramOrganizations(orgSearchUrl, searchValue, countryCode, orgSearchTags).then(
      (organizations: OrganizationSearchResp) => {
        if (organizations) {
          const { onLoaded } = this.props;
          onLoaded(organizations);
          this.setState({ organizations, loading: false });
        }
      },
    );
  };

  // eslint-disable-next-line react/sort-comp
  reset(overrides: PropertiesOf<FetchOrganizationState>) {
    this.setState({ ...FetchOrganizations.initialState, ...overrides });
  }

  // Prevent searching while user is actively typing
  // eslint-disable-next-line react/sort-comp
  debouncedFetch = debounce(this.fetch, 200);

  componentDidMount() {
    const { minimumSearchValueLength, searchValue } = this.props;
    this.mounted = true;

    if (searchValue.length >= minimumSearchValueLength) {
      this.reset({ loading: true });
      this.debouncedFetch();
    } else {
      logger.info(
        `No organizations fetched. Search term "${searchValue}" is less than ${minimumSearchValueLength} characters.`,
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { minimumSearchValueLength, searchValue } = this.props;

    if (prevProps.searchValue !== searchValue) {
      if (searchValue.length >= minimumSearchValueLength) {
        this.reset({ loading: true });
        this.debouncedFetch();
      } else {
        this.reset({ organizations: null, loading: false });
        logger.info(
          `No organizations fetched. Search term "${searchValue}" is less than ${minimumSearchValueLength} characters.`,
        );
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { isErrored, loading, organizations } = this.state;
    const {
      getItemProps,
      getMenuProps,
      highlightedIndex,
      itemToString,
      searchValue,
      programId,
      locale,
      urlAddSchoolForm,
      openOrgSearchEnabled,
    } = this.props;

    if (loading) {
      return (
        <div className="sid-organization-list__menu">
          <div className="sid-organization-list__message">
            {/* TODO: Define correct message ID from backend (backend need to send this message?) */}
            <FormattedHTMLMessage id="loading" defaultMessage="Loading..." />
          </div>
        </div>
      );
    }
    if (isErrored) {
      return (
        <div className="sid-organization-list__menu">
          <div className="sid-organization-list__message">
            {/* TODO: Define correct message ID from backend (backend need to send this message?) */}
            <FormattedHTMLMessage
              id="errorId.unknownError"
              defaultMessage="An unknown error occurred"
            />
          </div>
        </div>
      );
    }

    if (organizations && organizations.length > 0) {
      return (
        <OrganizationListComponent
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          highlightedIndex={highlightedIndex}
          itemToString={itemToString}
          organizations={organizations}
        >
          {searchValue &&
            !openOrgSearchEnabled &&
            urlAddSchoolForm &&
            (!organizations || organizations.length <= 5) && (
              <AddSchoolRequestRow
                urlAddSchoolForm={urlAddSchoolForm}
                programId={programId}
                locale={locale}
              />
            )}
        </OrganizationListComponent>
      );
    }

    if (
      searchValue &&
      !openOrgSearchEnabled &&
      urlAddSchoolForm &&
      (!organizations || organizations.length === 0)
    ) {
      return (
        <div className="sid-organization-list__menu">
          <AddSchoolRequestRow
            urlAddSchoolForm={urlAddSchoolForm}
            programId={programId}
            locale={locale}
          />
        </div>
      );
    }

    return null;
  }
}

export const FetchOrganizationsComponent = FetchOrganizations;
