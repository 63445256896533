import { Locale } from "../types/types";

/**
 * @description List of locales supported by the library, for runtime checks.
 */
export const Locales: Locale[] = [
  "ar",
  "bg",
  "cs",
  "da",
  "de",
  "el",
  "en-GB",
  "en-US",
  "es-ES",
  "es",
  "fi",
  "fr-CA",
  "fr",
  "ga",
  "hr",
  "hu",
  "id",
  "it",
  "iw",
  "ja",
  "ko",
  "lo",
  "lt",
  "ms",
  "nl",
  "no",
  "pl",
  "pt-BR",
  "pt",
  "ru",
  "sk",
  "sl",
  "sr",
  "sv",
  "th",
  "tr",
  "zh-HK",
  "zh",
];
