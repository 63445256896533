import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormSelectChoice, InputSelectComponentProps } from "../../../lib/types/types";
import { FieldLabel } from "../../FieldLabel";
import { InputSelectComponent } from "../../FormInputs/InputSelect/InputSelectComponent";

export const MedicalStatus = ({
  value,
  isErrored,
  options,
  onChange,
  intl,
  placeholder = "",
  onKeyDown = undefined,
}: InputSelectComponentProps) => {
  const inputId = "sid-medical-status";

  return (
    <div className={`sid-field ${inputId}`}>
      <div className="sid-l-space-top-md" />
      <FieldLabel
        text={<FormattedHTMLMessage id="status" defaultMessage="Status" />}
        htmlForLabel={inputId}
        id={`${inputId}-label`}
        displayClasses={placeholder && "sid-h-screen-reader-only"}
      />

      <InputSelectComponent
        fieldId="status"
        inputId={inputId}
        fieldLabelId={`${inputId}-label`}
        isErrored={isErrored}
        options={options}
        onChange={(status: FormSelectChoice) => onChange(status)}
        onKeyDown={onKeyDown}
        value={value}
        placeholder={
          placeholder || intl.formatMessage({ id: "statusPlaceholder", defaultMessage: "Status*" })
        }
        isRequired
      />
      {isErrored ? (
        <div className="sid-field-error">
          <FormattedHTMLMessage
            id="errorId.invalidStatus"
            defaultMessage="Invalid medical status"
          />
        </div>
      ) : null}
    </div>
  );
};

export const MedicalStatusComponent = injectIntl(MedicalStatus);
