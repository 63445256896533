import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import produce from "immer";
import { VerificationService, Locale, ViewModel } from "../../lib/types/types";
import { getSafe } from "../../lib/utils/objects";
import { getLocaleSafely } from "../../lib/intl/intl";
import { makeFormattedHTMLTagReplacer } from "../../lib/utils/customTagReplacer";
import { getPrivacyPolicyUrl } from "../../lib/ProgramTheme/programThemeGetters";
import { LinkExternal } from "../LinkExternal";
import { FaqLinkComponent } from "../FaqLink/FaqLinkComponent";

interface FormFooterProps {
  verificationService: VerificationService;
  children?: any;
}

const FormFooterComponent = ({ verificationService, children }: FormFooterProps) => {
  const { viewModel, updateViewModel } = verificationService;
  const countryChoice = getSafe<string>(() => viewModel.countryChoice.value, undefined);
  const locale: Locale = getLocaleSafely(viewModel);
  const privacyPolicyRef = React.useRef<any>({});
  const cleanupCommonTags =
    /(<div[^>]+>|<div>|<\/div>)|(<span[^>]+>|<span>|<\/span>)|(<p[^>]+>|<p>|<\/p>)/gm;

  // Store the footer message that the user was shown when the user submitted
  React.useEffect(() => {
    if (!privacyPolicyRef.current) {
      return;
    }
    const privacyPolicyText = privacyPolicyRef.current.innerHTML.replace(cleanupCommonTags, "");
    const updatedViewModel = produce(viewModel, (draft: ViewModel) => {
      if (!draft.metadata) {
        draft.metadata = {};
      }
      draft.metadata.submissionOptIn = privacyPolicyText;
    });

    updateViewModel(updatedViewModel);
  }, [privacyPolicyRef, locale]);

  return (
    <div className="sid-footer sid-h-small-text sid-l-space-top-md">
      <div className="sid-footer__shield">
        <img
          title="Powered by SheerID"
          alt="design element"
          src="https://s3.amazonaws.com/com.sheerid.resources/common/images/2018/icons/shield-check.svg"
        />
      </div>
      <div>
        <div className="sid-footer__one" ref={privacyPolicyRef}>
          {children || (
            // The footer text block is collapsed until focused and as such is interactive.
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            <div className="sid-footer__text" tabIndex={0}>
              <FormattedHTMLMessage
                id="footerText"
                defaultMessage="By submitting the personal information above, I acknowledge that my personal information is being collected under the <privacyPolicy>privacy policy</privacyPolicy> of the business from which I am seeking a discount, and I understand that my personal information will be shared with SheerID as a processor/third-party service provider in order for SheerID to confirm my eligibility for a special offer. <aboutSheerID>More about SheerID</aboutSheerID>."
              >
                {makeFormattedHTMLTagReplacer({
                  privacyPolicy: (content) => {
                    const url = getPrivacyPolicyUrl(verificationService.programTheme);
                    return url ? (
                      <LinkExternal className="sid-privacy-policy sid-link" href={url}>
                        {content}
                      </LinkExternal>
                    ) : (
                      content
                    );
                  },
                  aboutSheerID: (content) => (
                    <FaqLinkComponent
                      verificationService={verificationService}
                      linkText={content}
                    />
                  ),
                })}
              </FormattedHTMLMessage>
              {typeof countryChoice === "string" &&
              countryChoice.length > 0 &&
              countryChoice !== "US" ? (
                <FormattedHTMLMessage
                  id="informationTransferredToUS"
                  defaultMessage="Your information will be transferred to the United States and will be treated according to SheerID’s privacy policy."
                />
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { FormFooterComponent };
