import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FieldLabel } from "../../FieldLabel";
import { InputTextComponent } from "../../FormInputs/InputText/InputText";

interface PassedProps {
  explanation?: JSX.Element;
  label?: JSX.Element;
}

export const MemberId = ({
  value,
  isErrored,
  onChange,
  explanation,
  intl,
  placeholder = "",
  isRequired = false,
  label,
}: FormFieldComponentProps & PassedProps) => {
  const getDefaultExplanation = () =>
    !isRequired ? <FormattedHTMLMessage id="optional" defaultMessage="Optional" /> : "";
  return (
    <div className="sid-field sid-member-id">
      <div className="sid-l-space-top-md" />
      <FieldLabel
        text={label || <FormattedHTMLMessage id="memberId" defaultMessage="ID Number" />}
        htmlForLabel="sid-member-id"
        id="sid-member-id-label"
        displayClasses={`sid-field__label-with-explanation${
          placeholder && " sid-h-screen-reader-only"
        }`}
        isRequired={isRequired}
      >
        {explanation || getDefaultExplanation()}
      </FieldLabel>

      <InputTextComponent
        id="member-id"
        isErrored={isErrored}
        onChange={(e) => onChange(e.target.value)}
        placeholder={
          placeholder ||
          intl.formatMessage({ id: "memberIdPlaceholder", defaultMessage: "ID Number*" })
        }
        refId="memberId"
        value={value}
        aria-labelledby="sid-member-id-label"
      />

      {isErrored ? (
        <div className="sid-field-error">
          <FormattedHTMLMessage id="errorId.invalidMemberId" defaultMessage="Invalid ID Number" />
        </div>
      ) : null}
    </div>
  );
};

export const MemberIdComponent = injectIntl(MemberId);
