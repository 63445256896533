import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormSelectChoice, InputSelectComponentProps } from "../../../lib/types/types";
import { FieldLabel } from "../../FieldLabel";
import { InputSelectComponent } from "../../FormInputs/InputSelect/InputSelectComponent";

export const StateSelect = ({
  value,
  isErrored,
  options,
  onChange,
  onKeyDown,
  intl,
  placeholder = "",
  label = "",
  isRequired = false,
}: InputSelectComponentProps) => {
  const inputId = "sid-state";

  return (
    <div className={`sid-field ${inputId}`}>
      <div className="sid-l-space-top-md" />
      <FieldLabel
        text={label || <FormattedHTMLMessage id="state" defaultMessage="State where you live" />}
        htmlForLabel={inputId}
        id={`${inputId}-label`}
        displayClasses={placeholder && "sid-h-screen-reader-only"}
        isRequired={isRequired}
      />

      <InputSelectComponent
        fieldId="state"
        inputId={inputId}
        fieldLabelId={`${inputId}-label`}
        isErrored={isErrored}
        options={options}
        onChange={(state: FormSelectChoice) => onChange(state)}
        onKeyDown={onKeyDown}
        placeholder={
          placeholder ||
          intl.formatMessage({
            id: "statePlaceholder",
            defaultMessage: isRequired ? "State*" : "State",
          })
        }
        value={
          value
            ? {
                value: value.value,
                label: intl.formatMessage({
                  id: `states.${value.value}`,
                  defaultMessage: value.label || value.value,
                }),
              }
            : undefined
        }
      />
      {isErrored ? (
        <div className="sid-field-error">
          <FormattedHTMLMessage id="errorId.invalidState" defaultMessage="Invalid state" />
        </div>
      ) : null}
    </div>
  );
};

export const StateSelectComponent = injectIntl(StateSelect);
