import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import {
  FormSelectChoice,
  FormFieldComponentProps,
  VerificationServiceProps,
  Country,
  Locale,
} from "../../../lib/types/types";
import { TypeaheadComponent } from "../../FormInputs/Typeahead/TypeaheadComponent";
import { getSafe } from "../../../lib/utils/objects";
import { getOptions } from "../../../options/options";
import { getLocaleSafely } from "../../../lib/intl/intl";
import { getOrgSearchCountryTags } from "../../../lib/ProgramTheme/programThemeGetters";
import { FieldLabel } from "../../FieldLabel";

export const CollegeName = ({
  onChange,
  value,
  isErrored,
  verificationService,
  intl,
  placeholder = "",
  isRequired = true,
}: FormFieldComponentProps & VerificationServiceProps) => {
  const { programId, viewModel, verificationResponse } = verificationService;
  const locale: Locale = getLocaleSafely(viewModel, verificationResponse);
  const urlAddSchoolForm = (getOptions().urlAddSchoolForm || "").trim();
  const country = getSafe<Country>(() => verificationService.viewModel.countryChoice.value);
  const searchTags = getOrgSearchCountryTags(verificationService.programTheme, country);

  return (
    <div className="sid-field sid-college-name-id">
      <div className="sid-l-space-top-md" />
      <FieldLabel
        text={<FormattedHTMLMessage id="schoolName" defaultMessage="University / College name" />}
        htmlForLabel="sid-college-name"
        id="sid-college-name-label"
        displayClasses={placeholder && "sid-h-screen-reader-only"}
      />

      <TypeaheadComponent
        className={isErrored ? "sid-text-input--error" : ""}
        countryCode={country}
        minimumSearchValueLength={getOptions().minimumOrganizationSearchLength}
        onChange={(choice: FormSelectChoice) => onChange(choice)}
        placeholder={
          placeholder ||
          intl.formatMessage({
            id: "schoolNamePlaceholder",
            defaultMessage: "University / College name*",
          })
        }
        programId={programId}
        value={value}
        inputHtmlId="sid-college-name"
        openOrgSearchEnabled={verificationService.programTheme.openOrgSearchEnabled}
        orgSearchUrl={verificationService.programTheme.config.orgSearchUrl}
        orgSearchTags={searchTags}
        locale={locale}
        urlAddSchoolForm={urlAddSchoolForm}
        isRequired={isRequired}
      />
      {isErrored ? (
        <div className="sid-field-error">
          <FormattedHTMLMessage
            id="errorId.invalidOrganization"
            defaultMessage="You must select an organization from the list"
          />
        </div>
      ) : null}
    </div>
  );
};

export const CollegeNameComponent = injectIntl(CollegeName);
