import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FieldLabel } from "../../FieldLabel";
import { InputTextComponent } from "../../FormInputs/InputText/InputText";

interface PassedProps {
  explanation?: string | JSX.Element; // eslint-disable-line
  onKeyDown?: Function;
}

export const City = ({
  value,
  isErrored,
  onChange,
  intl,
  onKeyDown = undefined,
  placeholder = "",
}: FormFieldComponentProps & PassedProps) => (
  <div className="sid-field sid-city">
    <div className="sid-l-space-top-md" />
    <FieldLabel
      text={<FormattedHTMLMessage id="city" defaultMessage="City" />}
      htmlForLabel="sid-city"
      id="sid-city-label"
      displayClasses={placeholder && "sid-h-screen-reader-only"}
    />

    <InputTextComponent
      id="city"
      isErrored={isErrored}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={typeof onKeyDown === "function" ? (e) => onKeyDown(e) : undefined}
      placeholder={
        placeholder || intl.formatMessage({ id: "cityPlaceholder", defaultMessage: "City*" })
      }
      value={value}
      aria-labelledby="sid-city-label"
    />

    {isErrored ? (
      <div className="sid-field-error">
        <FormattedHTMLMessage id="errorId.invalidCity" defaultMessage="Invalid city" />
      </div>
    ) : null}
  </div>
);

export const CityComponent = injectIntl(City);
