import React from "react";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { FormFieldComponentProps } from "../../../lib/types/types";
import { FieldLabel } from "../../FieldLabel";
import { InputTextComponent } from "../../FormInputs/InputText/InputText";

interface PassedProps {
  explanation?: string | JSX.Element;
}

export const DriverLicenseNumber = ({
  value,
  isErrored,
  onChange,
  explanation,
  intl,
  placeholder = "",
  isRequired = false,
}: FormFieldComponentProps & PassedProps) => {
  const getDefaultExplanation = () =>
    !isRequired ? <FormattedHTMLMessage id="optional" defaultMessage="Optional" /> : "";
  return (
    <div className="sid-field sid-driver-license-number">
      <div className="sid-l-space-top-md" />
      <FieldLabel
        text={
          <FormattedHTMLMessage id="driverLicenseNumber" defaultMessage="Driver license number" />
        }
        htmlForLabel="sid-driver-license-number"
        id="sid-driver-license-number-label"
        displayClasses={`sid-field__label-with-explanation${
          placeholder && " sid-h-screen-reader-only"
        }`}
        isRequired={isRequired}
      >
        {explanation || getDefaultExplanation()}
      </FieldLabel>

      <InputTextComponent
        id="driver-license-number"
        isErrored={isErrored}
        onChange={(e) => onChange(e.target.value)}
        placeholder={
          placeholder ||
          intl.formatMessage({
            id: "driverLicenseNumberPlaceholder",
            defaultMessage: isRequired ? "Driver license number*" : "Driver license number",
          })
        }
        refId="driverLicenseNumber"
        value={value}
        required={isRequired}
        aria-labelledby="sid-driver-license-number-label"
      />

      {isErrored ? (
        <div className="sid-field-error">
          <FormattedHTMLMessage
            id="errorId.invalidDriverLicenseNumber"
            defaultMessage="Invalid driver license number"
          />
        </div>
      ) : null}
    </div>
  );
};

export const DriverLicenseNumberComponent = injectIntl(DriverLicenseNumber);
