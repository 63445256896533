import React from "react";
import { VerificationStep } from "../../lib/types/types";
import { LoadingSpinnerComponent as Spinner } from "../LoadingSpinner/LoadingSpinnerComponent";
import LoadingScreenMessage from "./LoadingScreenMessage";

interface Props {
  step?: VerificationStep;
}

// eslint-disable-next-line react/prop-types
const LoadingScreenComponent: React.FC<Props> = ({ step }) => (
  <div className="sid-loading-screen">
    <Spinner />
    {step && <LoadingScreenMessage step={step} />}
  </div>
);

export { LoadingScreenComponent };
