import React from "react";
import { FormattedHTMLMessage } from "react-intl";
import { Organization } from "../../lib/types/types";
import { CopyToClipboard } from "../CopyToClipboard/CopyToClipboardComponent";
import { getOrgNameWithoutLocation } from "../../lib/utils/requestOrgComponentHelpers/requestOrgComponentHelpers";

interface OrganizationResultComponentProps {
  organization: Organization;
  isEligible: boolean;
}

const eligibleSchoolIconSrc =
  "https://s3.amazonaws.com/com.sheerid.resources/common/images/requestOrganization/doc-right.svg";

export const OrganizationResultComponent = ({
  organization,
  isEligible,
}: OrganizationResultComponentProps) =>
  isEligible ? (
    <div className="sid-search-result">
      <img src={eligibleSchoolIconSrc} alt="School Icon" /> {organization.name}
      <CopyToClipboard
        textToCopy={getOrgNameWithoutLocation(organization.name)}
        notificationText={<FormattedHTMLMessage id="copied" defaultMessage="Copied" />}
      >
        <div className="sid-copy-to-clipboard-front" />
        <div className="sid-copy-to-clipboard-back" />
      </CopyToClipboard>
    </div>
  ) : (
    <div className="sid-search-result">
      <div className="sid-oval">
        <div className="sid-inner-x">x</div>
      </div>{" "}
      {organization.name}
    </div>
  );
