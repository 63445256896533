import React from "react";
import { IntlProvider } from "react-intl";
import { CustomTextWrapper } from "../CustomTextWrapper";
import { RequestOrganizationLoadStepComponent } from "./RequestOrganizationLoadStepComponent";

export const NewInnerRequestOrganizationFormComponent = ({
  locale,
  messages,
  requestOrganizationService,
}: {
  locale: any;
  messages: any;
  requestOrganizationService: any;
}) => (
  <IntlProvider locale="en-US" key={locale} messages={messages} textComponent={CustomTextWrapper}>
    <RequestOrganizationLoadStepComponent requestOrganizationService={requestOrganizationService} />
  </IntlProvider>
);
