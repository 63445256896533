import React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";

import { VerificationService, SuccessResponse } from "../../lib/types/types";
import { getLogoUrl } from "../../lib/ProgramTheme/programThemeGetters";

import { LogoComponent } from "../LogoComponent/LogoComponent";
import { CopyToClipboard } from "../CopyToClipboard/CopyToClipboardComponent";

interface StepSuccessProps {
  verificationService: VerificationService;
}

const redirect = (redirectDestination: string) => {
  const win = window.open(redirectDestination, "_parent");
  win.focus();
};

const StepSuccess = ({ verificationService }: StepSuccessProps) => {
  const hasLogo = !!getLogoUrl(verificationService.programTheme);
  const redirectDestination = (verificationService.verificationResponse as SuccessResponse)
    .redirectUrl;
  const showRedirect = Boolean(redirectDestination);
  const rewardCode =
    (verificationService.verificationResponse as SuccessResponse).rewardCode || "${rewardCode}"; // eslint-disable-line no-template-curly-in-string
  const noCodeType = verificationService.programTheme.config.offerType === "noCode";

  return (
    <div id="sid-step-success" className="sid-l-container">
      <div className="sid-header sid-l-horz-center sid-l-space-top-md">
        <div className="sid-l-horz-center">
          {hasLogo ? (
            <LogoComponent verificationService={verificationService} />
          ) : (
            <img
              className="sid-l-lead-image"
              alt=""
              src="https://s3.amazonaws.com/com.sheerid.resources/common/images/2018/icons/circle-check.svg"
            />
          )}
        </div>
        <div
          className="sid-header__title sid-l-horz-center sid-l-space-top-md"
          role="alert"
          aria-live="polite"
        >
          <FormattedHTMLMessage
            id="step.success.title"
            defaultMessage="Success! You've been verified."
          />
        </div>
        <div className="sid-header__subtitle sid-l-horz-center sid-l-two-thirds-width">
          <FormattedHTMLMessage
            id="step.success.subtitle"
            defaultMessage={`Here is your personal coupon code.
                        It is unique to you and can only be used once.`}
          />
        </div>
      </div>

      {noCodeType ? null : (
        <>
          <div className="sid-reward-code sid-l-space-top-lg sid-h-jumbo-text sid-l-horz-center">
            {rewardCode}
          </div>

          <div className="sid-l-space-top-md sid-h-bold-text sid-l-horz-center">
            <CopyToClipboard
              textToCopy={rewardCode}
              notificationText={<FormattedHTMLMessage id="copied" defaultMessage="Copied" />}
            >
              <FormattedHTMLMessage
                id="step.success.copyCouponCode"
                defaultMessage="Copy Coupon code"
              />
            </CopyToClipboard>
          </div>
        </>
      )}

      <div
        className={`sid-footer sid-l-horz-center ${
          noCodeType ? "sid-l-space-top-md" : "sid-l-space-top-lg"
        }`}
      >
        <FormattedHTMLMessage
          id="step.success.emailNotification"
          defaultMessage={`
                    We have emailed you a copy so you can use your code now or later. Look for the email from verify@sheerid.com
                `}
        />
      </div>

      {showRedirect ? (
        <div className="sid-submit sid-l-space-top-md">
          <button
            type="button"
            onClick={() => redirect(redirectDestination)}
            className="sid-btn sid-btn--dark"
          >
            <FormattedHTMLMessage
              id="step.success.redirectButtonText"
              defaultMessage="Use code now"
            />
          </button>
        </div>
      ) : (
        ""
      )}

      <div className="sid-footer sid-l-horz-center sid-l-space-top-lg sid-h-small-text">
        <FormattedMessage id="companyName" defaultMessage="{Company}">
          {(companyName) => (
            <FormattedHTMLMessage
              id="step.success.verificationOnly"
              defaultMessage={`
                                SheerID handles verification only.
                                Now that you've been approved, please direct all questions about the
                                promotion terms and/or code use to {companyName} customer service.
                            `}
              values={{ companyName }}
            />
          )}
        </FormattedMessage>
      </div>
    </div>
  );
};

export const StepSuccessComponent = StepSuccess;
